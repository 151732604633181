const config = {
  surveyTypeID: 103,
  surveyTitle: "UA TikTok Ad Test",
  videoSettingsNextRoute: "vid-tiktok-ua-key-metrics",
  creativeUploadPevRoute: "vid-tiktok-ua-setting",
  creativeUploadNextRoute: "vid-tiktok-ua-brand-strategy",
  personaNextRoute: "vid-tiktok-ua-key-metrics",
  personaPrevRoute: "vid-tiktok-ua-creative",
  keyMetricsNextRoute: "vid-tiktok-ua-launch",
  keyMetricsPrevRoute: "vid-tiktok-ua-creative",
  launchPrevRoute: "vid-tiktok-ua-key-metrics",
  brandLogoUpload: true,
  progressBarSteps: [
    {
      title: "General Settings",
      icon: "/images/icons/config.svg",
      index: 1,
      route: "vid-tiktok-ua-setting",
    },
    {
      title: "Creative",
      icon: "/images/icons/video2.svg",
      index: 2,
      route: "vid-tiktok-ua-creative",
    },
    // {
    //   title: "Brand Strategy",
    //   icon: "/images/icons/video3.svg",
    //   index: 3,
    //   route: "vid-tiktok-ua-brand-strategy",
    // },
    {
      title: "Key Metrics",
      icon: "/images/icons/video4.svg",
      index: 4,
      route: "vid-tiktok-ua-key-metrics",
    },
    {
      title: "Launch",
      icon: "/images/icons/launch.svg",
      index: 6,
      route: "vid-tiktok-ua-launch",
    },
  ],
  insertSurveyStimWS: "InsertSurveyStimYoutube",
  defaultSelectedAttrs: [
    {
      attributeID: 99,
      AttributeLabel: "a leader",
      zoneID: 8,
      ringID: 2,
      X: -0.7,
      Y: 0.87,
      zoneName: "Inspiring",
      ringName: "Differentiator",
      synonymLabel:
        " dominant, superior, masterful, central, top dog, foremost",
      selected: true,
      showFull: false,
    },
    {
      attributeID: 104,
      AttributeLabel: "active",
      zoneID: 1,
      ringID: 2,
      X: -0.86,
      Y: 0.14,
      zoneName: "Interesting",
      ringName: "Differentiator",
      synonymLabel: " energetic, busy, animated, full of life",
      selected: true,
      showFull: false,
    },
    {
      attributeID: 106,
      AttributeLabel: "ambitious",
      zoneID: 8,
      ringID: 2,
      X: -0.89,
      Y: 0.54,
      zoneName: "Inspiring",
      ringName: "Differentiator",
      synonymLabel: " competitive, aspiring, striving",
      selected: true,
      showFull: false,
    },
    {
      attributeID: 117,
      AttributeLabel: "brave",
      zoneID: 1,
      ringID: 2,
      X: -1.12,
      Y: 0.34,
      zoneName: "Interesting",
      ringName: "Differentiator",
      synonymLabel: " courageous, bold, daring, fearless, adventurous, gutsy",
      selected: true,
      showFull: false,
    },
    {
      attributeID: 125,
      AttributeLabel: "confident",
      zoneID: 8,
      ringID: 2,
      X: -0.57,
      Y: 0.52,
      zoneName: "Inspiring",
      ringName: "Differentiator",
      synonymLabel:
        " decisive, self confident, self assured, assertive, firm, good presenter",
      selected: true,
      showFull: false,
    },
    {
      attributeID: 131,
      AttributeLabel: "creative",
      zoneID: 1,
      ringID: 2,
      X: -0.79,
      Y: 0,
      zoneName: "Interesting",
      ringName: "Differentiator",
      synonymLabel: " innovative, imaginative, original, inventive, a dreamer",
      selected: true,
      showFull: false,
    },
    {
      attributeID: 140,
      AttributeLabel: "determined",
      zoneID: 8,
      ringID: 2,
      X: -0.45,
      Y: 0.74,
      zoneName: "Inspiring",
      ringName: "Differentiator",
      synonymLabel: " resolute, persistent, persevering",
      selected: true,
      showFull: false,
    },
    {
      attributeID: 149,
      AttributeLabel: "entrepreneurial",
      zoneID: 8,
      ringID: 2,
      X: -1.13,
      Y: 0.57,
      zoneName: "Inspiring",
      ringName: "Differentiator",
      synonymLabel:
        " prepared to take risks to make a profit, interested in new business ventures, business minded, enterprising",
      selected: true,
      showFull: false,
    },
    {
      attributeID: 151,
      AttributeLabel: "extroverted",
      zoneID: 1,
      ringID: 2,
      X: -0.78,
      Y: -0.33,
      zoneName: "Interesting",
      ringName: "Differentiator",
      synonymLabel: " outgoing, loves being part of a group, one of the crowd",
      selected: true,
      showFull: false,
    },
    {
      attributeID: 172,
      AttributeLabel: "influential",
      zoneID: 8,
      ringID: 2,
      X: -0.64,
      Y: 0.71,
      zoneName: "Inspiring",
      ringName: "Differentiator",
      synonymLabel: " important, connected, prominent",
      selected: true,
      showFull: false,
    },
    {
      attributeID: 174,
      AttributeLabel: "inspiring",
      zoneID: 8,
      ringID: 2,
      X: -0.48,
      Y: 0.26,
      zoneName: "Inspiring",
      ringName: "Differentiator",
      synonymLabel:
        " inspirational, encouraging, motivating, empowering, uplifting, visionary",
      selected: true,
      showFull: false,
    },
    {
      attributeID: 191,
      AttributeLabel: "modern",
      zoneID: 1,
      ringID: 2,
      X: -1.17,
      Y: -0.08,
      zoneName: "Interesting",
      ringName: "Differentiator",
      synonymLabel:
        " up to date, contemporary, modern, in touch with the times",
      selected: true,
      showFull: false,
    },
    {
      attributeID: 203,
      AttributeLabel: "passionate",
      zoneID: 1,
      ringID: 2,
      X: -0.57,
      Y: 0.15,
      zoneName: "Interesting",
      ringName: "Differentiator",
      synonymLabel:
        " passionate, motivated, dynamic, proactive, enthusiastic, driven, eager",
      selected: true,
      showFull: false,
    },
    {
      attributeID: 233,
      AttributeLabel: "sporty",
      zoneID: 1,
      ringID: 2,
      X: -1.3,
      Y: 0,
      zoneName: "Interesting",
      ringName: "Differentiator",
      synonymLabel: " athletic, fit, well built, physical",
      selected: true,
      showFull: false,
    },
    {
      attributeID: 247,
      AttributeLabel: "tough",
      zoneID: 8,
      ringID: 2,
      X: -0.94,
      Y: 0.85,
      zoneName: "Inspiring",
      ringName: "Differentiator",
      synonymLabel:
        " strong, formidable, powerful, potent, resilient, forceful, rugged, robust",
      selected: true,
      showFull: false,
    },
  ],
};

export default config;
