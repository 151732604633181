<template>
  <div class="quota-edit">
    <div class="quota-edit__data-info" v-if="loading">
      <Loader text="Loading..."></Loader>
    </div>
    <div v-else>
      <div class="box-wrapper__inner">
        <div class="input-group input-group--small">
          <label>*Cell</label>
          <a-select
            :default-value="quotaDetails.SurveyCellID"
            class="w-full"
            @change="changeCell"
            :class="{ error: showError && !quotaDetails.SurveyCellID }"
          >
            <a-select-option
              v-for="item in surveyCells"
              :key="item.SurveyCellID"
              :value="item.SurveyCellID"
            >
              {{ item.SurveyCellName }}
            </a-select-option>
          </a-select>
        </div>
        <div class="input-group input-group--small">
          <label>*Country</label>
          <a-select
            :default-value="quotaDetails.CountryLanguageID"
            :class="{ error: showError && !quotaDetails.CountryLanguageID }"
            class="w-full"
            v-model="quotaDetails.CountryLanguageID"
            @change="changeCountry"
          >
            <a-select-option
              v-for="item in countryOptions"
              :key="item.value"
              :value="item.value"
            >
              {{ item.label }}
            </a-select-option>
          </a-select>
        </div>
        <div class="input-group input-group--small">
          <label>*Quota Name</label>
          <a-input
            v-model="quotaDetails.QuotaName"
            :class="{ error: showError && !quotaDetails.QuotaName }"
            class="w-full"
          />
        </div>
        <div class="input-group input-group--small">
          <label>*Completes Goal</label>
          <a-input
            type="number"
            min="0"
            v-model="quotaDetails.QuotaLimit"
            :class="{ error: showError && !quotaDetails.QuotaLimit }"
            class="w-full"
          />
        </div>
        <div class="input-group input-group--small">
          <label>Age</label>
          <a-select
            mode="multiple"
            :value="quotaDetails.age"
            class="w-full"
            @change="changeAge"
            :maxTagCount="5"
          >
            <a-select-option
              v-for="(item, index) in ageOptions"
              :key="item"
              :value="item"
              @mousedown="handleOptionClick($event, index, 'age')"
            >
              {{ item }}
            </a-select-option>
          </a-select>
        </div>
        <div
          class="input-group input-group--small"
          v-if="stateOptions.length > 0"
        >
          <label>State</label>
          <a-select
            show-search
            mode="multiple"
            :value="!quotaDetails.State ? undefined : quotaDetails.State"
            class="w-full"
            @change="changeState"
            :maxTagCount="1"
            :filter-option="
              (input, option) =>
                option.componentOptions.children[0].text
                  .toLowerCase()
                  .indexOf(input.toLowerCase()) >= 0
            "
          >
            <a-select-option
              v-for="(item, index) in stateOptions"
              :key="item.Precode"
              :value="item.Precode"
              @mousedown="handleOptionClick($event, index, 'State')"
            >
              {{ item.OptionText }}
            </a-select-option>
          </a-select>
        </div>
        <div
          class="input-group input-group--small"
          v-if="provinceOptions.length > 0"
        >
          <label>Province</label>
          <a-select
            show-search
            mode="multiple"
            :value="!quotaDetails.Province ? undefined : quotaDetails.Province"
            class="w-full"
            @change="changeProvince"
            :maxTagCount="1"
            :filter-option="
              (input, option) =>
                option.componentOptions.children[0].text
                  .toLowerCase()
                  .indexOf(input.toLowerCase()) >= 0
            "
          >
            <a-select-option
              v-for="(item, index) in provinceOptions"
              :key="item.Precode"
              :value="item.Precode"
              @mousedown="handleOptionClick($event, index, 'Province')"
            >
              {{ item.OptionText }}
            </a-select-option>
          </a-select>
        </div>
        <div class="input-group input-group--small">
          <label>*Gender</label>
          <a-select
            :default-value="quotaDetails.Gender"
            v-model="quotaDetails.Gender"
            :class="{ error: showError && !quotaDetails.Gender }"
            class="w-full"
          >
            <a-select-option
              v-for="item in genderOptions"
              :key="item"
              :value="item"
            >
              {{ item }}
            </a-select-option>
          </a-select>
        </div>
        <div class="input-group input-group--small">
          <label>*Survey Length (mins)</label>
          <a-input
            type="number"
            min="0"
            :class="{ error: showError && !quotaDetails.BidLengthOfInterview }"
            v-model="quotaDetails.BidLengthOfInterview"
            class="w-full"
          />
        </div>
        <div class="input-group input-group--small">
          <label>*Est. Incidence (%)</label>
          <a-input
            type="number"
            min="0"
            v-model="quotaDetails.Incidence"
            :class="{ error: showError && !quotaDetails.Incidence }"
            class="w-full"
          />
        </div>
        <div class="input-group input-group--small">
          <label>*Quota CPI ($)</label>
          <a-input
            type="number"
            min="0"
            v-model="quotaDetails.QuotaCPI"
            :class="{ error: showError && !quotaDetails.QuotaCPI }"
            class="w-full"
            max="20"
            @input="validateQuotaCPI"
          />
        </div>
        <div class="input-group input-group--small">
          <label>*Panel Source</label>
          <a-select
            :default-value="
              !quotaDetails.TemplateID ? undefined : quotaDetails.TemplateID
            "
            @change="changePanelSource"
            class="w-full"
          >
            <a-select-option
              v-for="item in panelSource"
              :key="item.ID"
              :value="item.ID"
            >
              {{ item.Name }}
            </a-select-option>
          </a-select>
        </div>
        <div class="input-group input-group--small">
          <label>Group Quota</label>
          <a-checkbox v-model="quotaDetails.hasQuotaGroups"
            >Add quota to group to create exclusion
          </a-checkbox>
        </div>
        <div class="input-group input-group--small">
          <label>Primary Decision Maker</label>
          <a-select
            :default-value="
              !quotaDetails.PrimaryDecisionMaker
                ? undefined
                : quotaDetails.PrimaryDecisionMaker
            "
            @change="changePrimaryDecisionMaker"
            class="w-full"
          >
            <a-select-option
              v-for="item in primaryDecisionMakerOptions"
              :key="item.Precode"
              :value="item.Precode"
            >
              {{ item.OptionText }}
            </a-select-option>
          </a-select>
        </div>
        <div
          class="input-group input-group--small"
          v-if="ethnicityOptions.length > 0"
        >
          <label>Ethnicity</label>
          <a-select
            show-search
            mode="multiple"
            :value="
              !quotaDetails.Ethnicity ? undefined : quotaDetails.Ethnicity
            "
            class="w-full"
            @change="changeEthnicity"
            :maxTagCount="1"
            :filter-option="
              (input, option) =>
                option.componentOptions.children[0].text
                  .toLowerCase()
                  .indexOf(input.toLowerCase()) >= 0
            "
          >
            <a-select-option
              v-for="(item, index) in ethnicityOptions"
              :key="item.Precode"
              :value="item.Precode"
              @mousedown="handleOptionClick($event, index, 'Ethnicity')"
            >
              {{ item.OptionText }}
            </a-select-option>
          </a-select>
        </div>
        <div
          class="input-group input-group--small"
          v-if="standardHHIOptions.length > 0"
        >
          <label>Household Income</label>
          <a-select
            show-search
            mode="multiple"
            :value="
              !quotaDetails.StandardHHI ? undefined : quotaDetails.StandardHHI
            "
            class="w-full"
            @change="changeStandardHHI"
            :maxTagCount="1"
            :filter-option="
              (input, option) =>
                option.componentOptions.children[0].text
                  .toLowerCase()
                  .indexOf(input.toLowerCase()) >= 0
            "
          >
            <a-select-option
              v-for="(item, index) in standardHHIOptions"
              :key="item.Precode"
              :value="item.Precode"
              @mousedown="handleOptionClick($event, index, 'StandardHHI')"
            >
              {{ item.OptionText }}
            </a-select-option>
          </a-select>
        </div>
        <div class="input-group input-group--small">
          <label>MS is mobile</label>
          <a-select
            :default-value="
              !quotaDetails.MSismobile ? undefined : quotaDetails.MSismobile
            "
            class="w-full"
            @change="changeMSismobile"
          >
            <a-select-option
              v-for="item in msIsmobileOptions"
              :key="item.Precode"
              :value="item.Precode"
            >
              {{ item.OptionText }}
            </a-select-option>
          </a-select>
        </div>
      </div>
      <div class="flex justify-between gap-10 items-center mt-20">
        <p>* Required Fields</p>
        <div class="gap-10 flex items-center">
          <a-button type="default" @click="$emit('close')">Close</a-button>
          <a-button :loading="submitLoading" type="primary" @click="submit"
            >Submit</a-button
          >
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import * as wsUtils from "@/utils/wsUtils.js";
import Loader from "@/components/general/Loader.vue";
export default {
  name: "EditQuotaModal",
  components: {
    Loader,
  },
  props: {
    activeQuota: {
      type: Object,
    },
    surveyCells: {
      type: Array,
    },
  },

  data() {
    return {
      loading: false,
      quotaDetails: {
        age: [],
        CountryLanguageID: null,
      },
      options: [],
      ageOptions: Array.from({ length: 68 }, (_, i) => (13 + i).toString()),
      countryOptions: [
        { value: 9, label: "USA (EN)" },
        { value: 5, label: "Australia (EN)" },
        { value: 6, label: "Canada (EN)" },
        { value: 25, label: "Canada (FR)" },
        { value: 10, label: "France (FR)" },
        { value: 13, label: "Italy" },
        { value: 21, label: "Mexico (Spanish)" },
        { value: 57, label: "New Zealand (EN)" },
        { value: 18, label: "Russia" },
        { value: 22, label: "Spain" },
        { value: 8, label: "UK (EN)" },
        { value: 1, label: "China" },
        { value: 11, label: "Germany" },
        { value: 14, label: "Japanese" },
        { value: 54, label: "Thailand (TH)" },
        { value: 82, label: "UAE (AR)" },
        { value: 16, label: "Brazil (PR)" },
        { value: 108, label: "Brazil (EN)" },
      ],
      genderOptions: ["Both", "Male", "Female"],
      submitLoading: false,
      showError: false,
      panelSource: [],
      lastSelectedIndex: null,
      skipChangeAge: false,
      skipChangeState: false,
      skipChangeProvince: false,
      skipChangeEthnicity: false,
      skipChangeStandardHHI: false,
    };
  },
  computed: {
    parsedAge() {
      try {
        return JSON.parse(this.quotaDetails.age);
      } catch (error) {
        console.error("Error parsing age:", error);
        return [];
      }
    },
    stateOptions() {
      const stateObject = this.options.find((obj) => obj.text === "State");

      return stateObject ? stateObject.options : [];
    },
    provinceOptions() {
      const provinceObject = this.options.find(
        (obj) => obj.text === "Province"
      );

      if (provinceObject) {
        provinceObject.options = provinceObject.options.filter(
          (option) => option.Precode !== "0"
        );
      }

      return provinceObject ? provinceObject.options : [];
    },

    primaryDecisionMakerOptions() {
      const stateObject = this.options.find(
        (obj) => obj.text === "PrimaryDecisionMaker"
      );
      return stateObject ? stateObject.options : [];
    },
    ethnicityOptions() {
      const stateObject = this.options.find((obj) => obj.text === "Ethnicity");
      return stateObject ? stateObject.options : [];
    },
    standardHHIOptions() {
      const stateObject = this.options.find(
        (obj) => obj.text === "StandardHHI"
      );
      return stateObject ? stateObject.options : [];
    },
    msIsmobileOptions() {
      const stateObject = this.options.find((obj) => obj.text === "MSismobile");
      return stateObject ? stateObject.options : [];
    },
  },
  methods: {
    validateQuotaCPI(event) {
      const value = parseFloat(event.target.value);
      if (value > 20) {
        this.quotaDetails.QuotaCPI = 20;
      } else if (value < 0) {
        this.quotaDetails.QuotaCPI = 0;
      } else {
        this.quotaDetails.QuotaCPI = value;
      }
    },
    getDetails() {
      this.loading = true;
      const data = {
        wsName: "GetCellFieldsValueForEdit",
        data: {
          fulcrumNumber: this.activeQuota.surveyNumber,
        },
      };

      const getDetails = new Promise((resolve) => {
        wsUtils.ApiPost(data, resolve);
      });

      getDetails.then((data) => {
        if (!data.success) {
          this.$notification["error"]({
            message: "Error!",
            description:
              "Something went wrong, please try again or contact our support.",
            placement: "bottomLeft",
            duration: 4,
          });
          this.$emit("close");
          this.loading = false;
        } else {
          if (
            data.fulcrumData.age &&
            typeof data.fulcrumData.age === "string"
          ) {
            try {
              const parsedAge = JSON.parse(data.fulcrumData.age);
              if (Array.isArray(parsedAge)) {
                data.fulcrumData.age = parsedAge;
              }
            } catch (error) {
              console.error("Error parsing age:", error);
            }
          }
          if (
            data.fulcrumData.State &&
            typeof data.fulcrumData.State === "string"
          ) {
            try {
              const parsedState = JSON.parse(data.fulcrumData.State);
              if (Array.isArray(parsedState)) {
                data.fulcrumData.State = parsedState;
              }
            } catch (error) {
              console.error("Error parsing state:", error);
            }
          }
          if (
            data.fulcrumData.Province &&
            typeof data.fulcrumData.Province === "string"
          ) {
            try {
              const parsedProvince = JSON.parse(data.fulcrumData.Province);
              if (Array.isArray(parsedProvince)) {
                data.fulcrumData.Province = parsedProvince.filter(
                  (item) => item !== "0"
                );
              }
            } catch (error) {
              console.error("Error parsing Province:", error);
            }
          }
          if (
            data.fulcrumData.PrimaryDecisionMaker &&
            typeof data.fulcrumData.PrimaryDecisionMaker === "string"
          ) {
            try {
              const parsedPrimaryDecisionMaker = JSON.parse(
                data.fulcrumData.PrimaryDecisionMaker
              );
              if (Array.isArray(parsedPrimaryDecisionMaker)) {
                data.fulcrumData.PrimaryDecisionMaker =
                  parsedPrimaryDecisionMaker;
              }
            } catch (error) {
              console.error("Error parsing PrimaryDecisionMaker:", error);
            }
          }
          if (
            data.fulcrumData.Ethnicity &&
            typeof data.fulcrumData.Ethnicity === "string"
          ) {
            try {
              const parsedEthnicity = JSON.parse(data.fulcrumData.Ethnicity);
              if (Array.isArray(parsedEthnicity)) {
                data.fulcrumData.Ethnicity = parsedEthnicity;
              }
            } catch (error) {
              console.error("Error parsing Ethnicity:", error);
            }
          }
          if (
            data.fulcrumData.StandardHHI &&
            typeof data.fulcrumData.StandardHHI === "string"
          ) {
            try {
              const parsedStandardHHI = JSON.parse(
                data.fulcrumData.StandardHHI
              );
              if (Array.isArray(parsedStandardHHI)) {
                data.fulcrumData.StandardHHI = parsedStandardHHI;
              }
            } catch (error) {
              console.error("Error parsing StandardHHI:", error);
            }
          }
          if (
            data.fulcrumData.MSismobile &&
            typeof data.fulcrumData.MSismobile === "string"
          ) {
            try {
              const parsedMSismobile = JSON.parse(data.fulcrumData.MSismobile);
              if (Array.isArray(parsedMSismobile)) {
                data.fulcrumData.MSismobile = parsedMSismobile;
              }
            } catch (error) {
              console.error("Error parsing MSismobile:", error);
            }
          }
          this.quotaDetails = data.fulcrumData;
          this.getOptionsData(data.fulcrumData.CountryLanguageID);
        }
      });
    },
    getOptionsData(countryLanguageID) {
      this.loading = true;
      const data = {
        wsName: "GetETHNICITYOptionsData",
        data: {
          CountryLanguageID: countryLanguageID,
        },
      };

      const getOptionsData = new Promise((resolve) => {
        wsUtils.ApiPost(data, resolve);
      });

      getOptionsData.then((data) => {
        this.options = data.optionsData;
        this.panelSource = data.panelSource;
        this.loading = false;
      });
    },
    changeCell(cellId) {
      const cellName = this.surveyCells.find(
        (item) => item.SurveyCellID === cellId
      ).SurveyCellName;
      this.quotaDetails.SurveyCellID = cellId;
      this.quotaDetails.SurveyCellName = cellName;
    },
    changePrimaryDecisionMaker(precode) {
      this.quotaDetails.PrimaryDecisionMaker = [];
      this.quotaDetails.PrimaryDecisionMaker.push(precode);
    },
    changePanelSource(id) {
      this.quotaDetails.TemplateID = id;
    },
    changeEthnicity(precode) {
      if (this.skipChangeEthnicity) {
        this.skipChangeEthnicity = false;
        return;
      }
      this.quotaDetails.Ethnicity = precode;
    },
    changeStandardHHI(precode) {
      if (this.skipChangeStandardHHI) {
        this.skipChangeStandardHHI = false;
        return;
      }
      this.quotaDetails.StandardHHI = precode;
    },
    changeMSismobile(precode) {
      this.quotaDetails.MSismobile = [];
      this.quotaDetails.MSismobile.push(precode);
    },
    changeAge(precode) {
      if (this.skipChangeAge) {
        this.skipChangeAge = false;
        return;
      }
      this.quotaDetails.age = precode;
    },
    handleOptionClick(event, index, key) {
      this[`skipChange${key.charAt(0).toUpperCase() + key.slice(1)}`] = true;
      const optionsRaw =
        this[
          key === "StandardHHI"
            ? "standardHHIOptions"
            : `${key.toLowerCase()}Options`
        ];
      const options =
        Array.isArray(optionsRaw) && typeof optionsRaw[0] === "object"
          ? optionsRaw.map((obj) => obj.Precode)
          : optionsRaw;

      const selectedValues = this.quotaDetails[key];

      if (event.shiftKey && this.lastSelectedIndex !== null) {
        const start = Math.min(this.lastSelectedIndex, index);
        const end = Math.max(this.lastSelectedIndex, index);
        const range = options.slice(start, end + 1);
        const allInSelection = range.every((item) =>
          selectedValues.includes(item)
        );

        if (allInSelection) {
          this.quotaDetails[key] = selectedValues.filter(
            (item) => !range.includes(item)
          );
        } else {
          this.quotaDetails[key] = Array.from(
            new Set([...selectedValues, ...range])
          ).slice();
        }

        this.lastSelectedIndex = null;
      } else if (event.shiftKey) {
        this.lastSelectedIndex = index;
      } else {
        const value = options[index];
        if (selectedValues.includes(value)) {
          this.quotaDetails[key] = selectedValues.filter(
            (item) => item !== value
          );
        } else {
          this.quotaDetails[key] = [...selectedValues, value];
        }
        this.lastSelectedIndex = null;
      }
    },

    changeState(precode) {
      console.log("this.skipChangeState", this.skipChangeState);
      if (this.skipChangeState) {
        this.skipChangeState = false;
        return;
      }
      this.quotaDetails.State = precode;
    },
    changeProvince(precode) {
      if (this.skipChangeProvince) {
        this.skipChangeProvince = false;
        return;
      }
      this.quotaDetails.Province = precode;
    },
    changeCountry() {
      this.getOptionsData(this.quotaDetails.CountryLanguageID);
    },
    submit() {
      const requiredProperties = [
        "SurveyCellName",
        "QuotaName",
        "CountryLanguageID",
        "QuotaLimit",
        "Gender",
        "BidLengthOfInterview",
        "Incidence",
        "QuotaCPI",
      ];

      for (const property of requiredProperties) {
        const value = this.quotaDetails[property];
        if (value === null || value === undefined || value === "") {
          this.showError = true;
          this.$notification["error"]({
            message: "Error!",
            description: `Please fill in all required fields.`,
            placement: "bottomLeft",
            duration: 5,
          });
          return;
        }
      }

      this.showError = false;

      this.submitLoading = true;

      const propertiesToConvert = [
        "age",
        "State",
        "Province",
        "PrimaryDecisionMaker",
        "Ethnicity",
        "StandardHHI",
        "MSismobile",
      ];

      const convertedQuotaDetails = { ...this.quotaDetails };
      if (
        !Array.isArray(convertedQuotaDetails.age) ||
        convertedQuotaDetails.age.length === 0
      ) {
        convertedQuotaDetails.age = this.ageOptions;
      }

      if (
        !Array.isArray(convertedQuotaDetails.State) ||
        convertedQuotaDetails.State.length === 0
      ) {
        convertedQuotaDetails.State = this.stateOptions.map(
          (item) => item.Precode
        );
      }
      if (this.stateOptions.length === 0) {
        convertedQuotaDetails.State = [];
      }
      if (
        !Array.isArray(convertedQuotaDetails.Province) ||
        convertedQuotaDetails.Province.length === 0
      ) {
        convertedQuotaDetails.Province = this.provinceOptions.map(
          (item) => item.Precode
        );
      }
      if (this.provinceOptions.length === 0) {
        convertedQuotaDetails.Province = [];
      }

      propertiesToConvert.forEach((property) => {
        if (Array.isArray(convertedQuotaDetails[property])) {
          convertedQuotaDetails[property] = JSON.stringify(
            convertedQuotaDetails[property]
          );
        }
      });

      const data = {
        wsName: "UpdateFulcrumData",
        data: convertedQuotaDetails,
      };

      const submit = new Promise((resolve) => {
        wsUtils.ApiPost(data, resolve);
      });

      submit.then((data) => {
        if (data.success) {
          this.$notification["success"]({
            message: "Well done!",
            description: "Data updated successfully.",
            placement: "bottomLeft",
            duration: 4,
          });
          this.$emit("close", "edit");
        } else {
          this.$notification["error"]({
            message: "Error!",
            description: data.message,
            placement: "bottomLeft",
            duration: 8,
          });
        }
        this.submitLoading = false;
      });
    },
  },
  mounted() {
    this.getDetails();
  },
};
</script>

<style lang="scss" scoped>
.quota-edit {
  &__data-info {
    min-height: 570px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
</style>
