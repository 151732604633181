<template>
  <div class="shelf-library body-bcg">
    <a-breadcrumb class="breadcrum">
      <template v-if="user !== null && user.SkinIdentifier !== 'pg'">
        <a-breadcrumb-item>
          <router-link to="/">{{
            client ? client.ClientName : ""
          }}</router-link>
        </a-breadcrumb-item>
        <a-breadcrumb-item>
          <router-link to="/shelf-search">Project Planning Tool</router-link>
        </a-breadcrumb-item>
        <span class="weight-500">Project Estimate</span>
      </template>
    </a-breadcrumb>

    <div class="page-padding">
      <div class="page-title mb-35">
        <icon-base
          :width="32"
          :height="33"
          :viewBox1="25"
          :viewBox2="25"
          iconName="users"
          iconColor="transparent"
          iconStroke="#4318FF"
          ><icon-users
        /></icon-base>
        <div class="page-title__text">
          <h1>Project Estimate</h1>
          <span>Scoping Estimate</span>
        </div>
      </div>

      <div class="flex justify-between items-end mb-10">
        <div class="shelf-library__cost mb-10">
          <div v-if="!hsUser" class="shelf-library__cost-item">
            <span>${{ formatPrice(adaptPrice) }}</span>
            <h2>Total price</h2>
            <i
              v-if="priceLoader && !shelfMarketLoader"
              class="circle-loader"
            ></i>
          </div>

          <div class="shelf-library__cost-item shelf-library__cost-item--pack">
            <span>{{ deepDiveNumber * sampleNumber }}</span>
            <h2>Pack Evaluations <span>Per Leg</span></h2>
            <a-tooltip placement="top" overlayClassName="ant-tooltip--step-nav">
              <template slot="title">
                This is the combined number of respondents across all deep dive
                packs within the leg who will complete full package diagnostics.
              </template>
              <a-icon type="info-circle" />
            </a-tooltip>
          </div>

          <div class="shelf-library__cost-item shelf-library__cost-item--shelf">
            <span v-if="implicitValue">{{ deepDiveNumber * sampleNumber + 200 }}</span>
            <span v-else>{{ deepDiveNumber * sampleNumber }}</span>
            <h2>Shelf Evaluations <span>Per Leg</span></h2>
            <a-tooltip placement="top" overlayClassName="ant-tooltip--step-nav">
              <template slot="title">
                This is the combined number of respondents who will complete all
                shelf exercises including Shopping, Attention and Findability
                within the leg.
              </template>
              <a-icon type="info-circle" />
            </a-tooltip>
          </div>

          <div
            class="
              shelf-library__cost-item shelf-library__cost-item--sample-size
            "
          >
            <span v-if="implicitValue">{{
              (deepDiveNumber * sampleNumber + 200) * legsNumber
            }}</span>
              <span v-else>{{
              (deepDiveNumber * sampleNumber) * legsNumber
            }}</span>
            <h2>Total Sample Size</h2>
            <a-tooltip placement="top" overlayClassName="ant-tooltip--step-nav">
              <template slot="title">
                This is the total combined number of respondents across all
                legs.
              </template>
              <a-icon type="info-circle" />
            </a-tooltip>
          </div>
          <div
            class="shelf-library__cost-item shelf-library__cost-item--estimated"
          >
            <span>${{ formatPrice(estimatedCost) }}</span>
            <h2>Estimated OOP Costs</h2>
            <i
              v-if="priceLoader && !shelfMarketLoader"
              class="circle-loader"
            ></i>
          </div>
        </div>

        <a-dropdown
          :visible="dropdownVisible"
          :trigger="['click']"
          @visibleChange="handleVisibleChange"
          overlayClassName="filters-dropdown"
        >
          <a
            class="filters-dropdown-cta"
            :class="{ 'is-active': dropdownVisible }"
            @click="dropdownVisible = true"
          >
            <i></i>
            <icon-base
              :width="24"
              :height="24"
              :viewBox1="24"
              :viewBox2="24"
              iconName="filters"
              iconColor="transparent"
              iconStroke="#1C1C1C"
              ><icon-filters
            /></icon-base>
            Change project specs
          </a>
          <template #overlay>
            <a-menu>
              <a-menu-item key="0">
                <div class="flex gap-10">
                  <div class="input-group input-group--small flex-1">
                    <label>Category</label>
                    <div class="position-r">
                      <Skeleton
                        :borderRadius="4"
                        v-if="shelfCategoryLoader"
                      ></Skeleton>
                      <a-select
                        class="fullWidth"
                        option-filter-prop="children"
                        show-search
                        placeholder="Please select"
                        :default-value="chosenShelfCategory"
                        :key="Math.random()"
                        @change="(chosenShelfCategory = $event), setQuery()"
                      >
                        <a-select-option
                          v-for="item in shelfCategoryInfo"
                          :key="item.CetegoryName"
                          :value="item.CetegoryName"
                        >
                          {{ item.CetegoryName }}
                        </a-select-option>
                      </a-select>
                    </div>
                  </div>
                  <div class="input-group input-group--small flex-1">
                    <label>Market</label>
                    <div class="position-r">
                      <Skeleton
                        :borderRadius="4"
                        v-if="shelfMarketLoader"
                      ></Skeleton>
                      <a-select
                        class="fullWidth"
                        option-filter-prop="children"
                        show-search
                        :key="Math.random()"
                        placeholder="Please select"
                        :default-value="chosenShelfCountry"
                        @change="(chosenShelfCountry = $event), setQuery()"
                      >
                        <a-select-option
                          v-for="item in shelfMarketInfo"
                          :key="item.CountryName"
                          :value="item.CountryName"
                        >
                          {{ item.CountryName }}
                        </a-select-option>
                      </a-select>
                    </div>
                  </div>
                </div>
              </a-menu-item>
              <a-menu-item key="1"> </a-menu-item>
              <a-menu-item key="2">
                <div class="flex gap-10">
                  <div class="input-group input-group--small w-full">
                    <div class="flex items-center">
                      <label class="lineLable">Legs*</label>
                      <a-tooltip
                        placement="top"
                        overlayClassName="ant-tooltip--step-nav"
                      >
                        <template slot="title">
                          <span
                            >Including your current design, how many different
                            designs do you want to test? This will be your total
                            number of cells.</span
                          >
                        </template>
                        <a-icon type="info-circle" />
                      </a-tooltip>
                    </div>
                    <div class="position-r">
                      <Skeleton
                        :borderRadius="4"
                        v-if="shelfMarketLoader"
                      ></Skeleton>
                      <a-input-number
                        class="fullWidth"
                        size="large"
                        type="number"
                        :min="1"
                        :max="100000"
                        v-model="legsNumber"
                      />
                    </div>
                  </div>
                  <div class="input-group input-group--small w-full">
                    <div class="flex items-center">
                      <label class="lineLable">Deep dives*</label>
                      <a-tooltip
                        placement="top"
                        overlayClassName="ant-tooltip--step-nav"
                      >
                        <template slot="title">
                          <span
                            >How many priority products do you want full
                            packaging diagnostics for? Full packaging
                            diagnostics include: on-pack navigation, affective
                            heuristics, automatic implicit associations, pack
                            functionality and detailed sentiment feedback on
                            specific pack elements.</span
                          >
                        </template>
                        <a-icon type="info-circle" />
                      </a-tooltip>
                    </div>
                    <div class="position-r">
                      <Skeleton
                        :borderRadius="4"
                        v-if="shelfMarketLoader"
                      ></Skeleton>
                      <a-input-number
                        class="fullWidth"
                        size="large"
                        type="number"
                        :min="1"
                        :max="100000"
                        v-model="deepDiveNumber"
                      />
                    </div>
                  </div>
                  <div class="input-group input-group--small w-full">
                    <div class="flex items-center">
                      <label class="lineLable">Sample size*</label>
                      <a-tooltip
                        placement="top"
                        overlayClassName="ant-tooltip--step-nav"
                      >
                        <template slot="title">
                          <span
                            >This will determine the number of consumers doing
                            full packaging diagnostics for each priority
                            product.</span
                          >
                        </template>
                        <a-icon type="info-circle" />
                      </a-tooltip>
                    </div>
                    <div class="position-r">
                      <Skeleton
                        :borderRadius="4"
                        v-if="shelfMarketLoader"
                      ></Skeleton>
                      <a-select
                        class="fullWidth"
                        option-filter-prop="children"
                        show-search
                        placeholder="Select a category"
                        :key="Math.random()"
                        :default-value="sampleNumber"
                        @change="sampleNumber = $event"
                      >
                        <a-select-option
                          v-for="item in sampleSize"
                          :key="item"
                          :value="item"
                        >
                          {{ item }}
                        </a-select-option>
                      </a-select>
                    </div>
                  </div>
                  <div class="input-group input-group--small w-full">
                    <div class="flex items-center">
                      <label class="lineLable">Implicit*</label>
                    </div>
                    <div class="position-r">
                      <Skeleton
                        :borderRadius="4"
                        v-if="shelfMarketLoader"
                      ></Skeleton>
                      <a-select
                        class="fullWidth"
                        option-filter-prop="children"
                        show-search
                        placeholder="Select implicit"
                        :key="Math.random()"
                        :default-value="implicitValue"
                        @change="(implicitValue = $event), setQuery()"
                      >
                        <a-select-option
                          v-for="item in isImplicit"
                          :key="item.text"
                          :value="item.value"
                        >
                          {{ item.text }}
                        </a-select-option>
                      </a-select>
                    </div>
                  </div>
                </div>
              </a-menu-item>

              <a-menu-item key="3">
                <div class="flex justify-end gap-15 mt-5 mb-5">
                  <a-button
                    class="management-system__filters-search-btn"
                    type="primary"
                    @click="shelfLibrary"
                    :disabled="viewDisabled"
                  >
                    <div class="flex" v-if="priceLoader">
                      <i class="circle-loader"></i>
                    </div>
                    <span v-else>View</span>
                  </a-button>
                </div>
              </a-menu-item>
            </a-menu>
          </template>
        </a-dropdown>
      </div>

      <div class="box-wrapper">
        <div
          class="
            flex
            items-center
            justify-center
            shelf-library__loader
            flex-col
          "
          v-if="shelfLibraryInfo.length === 0 && !shelfLoader"
        >
          <icon-base
            :width="60"
            :height="60"
            :viewBox1="32"
            :viewBox2="32"
            iconName="no-results"
            iconColor="#415262"
            iconStroke="#fff"
            class="mb-10"
            ><icon-no-results
          /></icon-base>
          <p class="mb-20">There is no Shelf for chosen criteria...</p>
          <a-button type="primary" @click="dropdownVisible = true">
            Change project specs</a-button
          >
        </div>

        <p
          class="flex items-center justify-center shelf-library__loader"
          v-if="shelfLoader"
        >
          <i class="circle-loader"></i>Loading Shelf Library...
        </p>

        <div class="products-grid" v-else>
          <div
            class="shelf-library-item"
            v-for="item in shelfLibraryInfo"
            :key="item.shelfID"
          >
            <div class="shelf-library-item__image image-loading">
              <div class="shelf-library-item__cta">
                <a-button type="text" @click="productDetails(item.shelfID)"
                  ><font-awesome-icon icon="list" /> Shelf Details</a-button
                >
                <a-button type="text" @click="storeDemo(item.cipId)"
                  ><font-awesome-icon icon="store" /> Store Demo</a-button
                >
              </div>
              <img
                :src="item.thumbImageURL"
                @error="$event.target.src = item.imageURL"
                @load="
                  $event.target.parentNode.classList.toggle(
                    'image-loading--loaded'
                  )
                "
                alt="Shelf"
              />
            </div>
            <ul class="shelf-library-item__info">
              <li>
                <icon-base
                  :width="24"
                  :height="24"
                  :viewBox1="24"
                  :viewBox2="24"
                  iconName="box"
                  iconColor="#fff"
                  ><icon-box
                /></icon-base>
                {{ item.Name }}
              </li>
              <li>
                <icon-base
                  :width="24"
                  :height="24"
                  :viewBox1="24"
                  :viewBox2="24"
                  iconName="cart"
                  iconColor="#fff"
                  ><icon-cart
                /></icon-base>
                {{ item.numberOfProducts }} Products
              </li>
              <li>
                <icon-base
                  :width="24"
                  :height="24"
                  :viewBox1="24"
                  :viewBox2="24"
                  iconName="calendar"
                  iconColor="#fff"
                  ><icon-calendar
                /></icon-base>
                {{ item.date }}
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
    <StepNaviBar
      :cancelButton="false"
      :conciergeAssistance="true"
      :generalNaviBar="true"
    />
  </div>
</template>
<script>
import * as wsUtils from "@/utils/wsUtils.js";
import * as jsUtils from "@/utils/jsUtils.js";
import Skeleton from "@/components/general/Skeleton.vue";
import priceFormating from "@/mixins/priceFormating.js";
import StepNaviBar from "@/components/videoAds/StepNaviBar1.vue";
import IconBase from "@/components/general/IconBase.vue";
import IconUsers from "@/components/icons/IconUsers.vue";
import IconFilters from "@/components/icons/IconFilters.vue";
import IconNoResults from "@/components/icons/IconNoResults.vue";
import IconCalendar from "@/components/icons/IconCalendar.vue";
import IconCart from "@/components/icons/IconCart.vue";
import IconBox from "@/components/icons/IconBox.vue";

export default {
  name: "ShelfLibrary",
  components: {
    Skeleton,
    StepNaviBar,
    IconBase,
    IconUsers,
    IconFilters,
    IconNoResults,
    IconCalendar,
    IconCart,
    IconBox,
  },
  mixins: [priceFormating],
  props: {
    show: {
      type: Boolean,
      default() {
        return false;
      },
    },
  },
  data() {
    return {
      shelfLibraryInfo: [],
      shelfInfoLoader: false,
      chosenShelfCategory: "All",
      chosenShelfCountry: "All",
      shelfCategoryInfo: [],
      shelfMarketInfo: [],
      shelfLoader: true,
      priceLoader: false,
      shelfCategoryLoader: false,
      shelfMarketLoader: false,
      adaptPrice: null,
      estimatedCost: null,
      sampleSize: [100, 150, 200, 300],
      isImplicit: [{"text":"Yes","value":true}, {"text":"No", "value":false}],
      legsNumber: null,
      deepDiveNumber: null,
      sampleNumber: null,
      implicitValue: null,
      implicitDisplayValue: null,
      editCategory: false,
      user: null,
      client: null,
      dropdownVisible: false,
      hsUser: false,
    };
  },
  computed: {
    viewDisabled() {
      return (
        !this.legsNumber ||
        !this.deepDiveNumber ||
        !this.chosenShelfCategory ||
        !this.chosenShelfCountry
      );
    },
  },
  methods: {
    handleVisibleChange(visible) {
      this.dropdownVisible = visible;
    },
    shelfLibrary() {
      this.dropdownVisible = false;
      this.shelfLoader = true;
      const getShelfLibrary = new Promise((resolve) => {
        wsUtils.GetShelfLibrary(
          {
            userId: this.user.EncrypteduserID,
            category: this.chosenShelfCategory,
            country: this.chosenShelfCountry,
          },
          resolve
        );
      });

      getShelfLibrary.then((data) => {
        this.setShelfLibraryInfo(data);
        this.adaptPackPrice();
        this.shelfInfoLoader = false;
        this.shelfLoader = false;
      });
    },
    adaptPackPrice() {
      this.priceLoader = true;
      const getAdaptPackPrice = new Promise((resolve) => {
        wsUtils.GetAdaptPackPrice(
          {
            sample: this.sampleNumber || 100,
            legs: this.legsNumber || 1,
            deepDive: this.deepDiveNumber || 1,
            surveyTypeId: 93,
            isImplicit: this.implicitValue || false,
          },
          resolve
        );
      });

      getAdaptPackPrice.then((data) => {
        this.adaptPrice = data.total;
        this.estimatedCost = data.estimatedCost;
        this.priceLoader = false;
      });
    },
    setShelfLibraryInfo(data) {
      data.forEach((element) => {
        element.thumbImageURL = element.imageURL.replace(
          /(\.[\w\d_-]+)$/i,
          "_thumb$1"
        );
      });
      this.shelfLibraryInfo = data;
    },
    shelfCategory() {
      this.shelfCategoryLoader = true;
      const getShelfCategory = new Promise((resolve) => {
        wsUtils.GetShelfCategory(
          {
            userId: this.user.EncrypteduserID,
          },
          resolve
        );
      });

      getShelfCategory.then((data) => {
        this.shelfCategoryInfo = data;
        this.shelfCategoryLoader = false;
      });
    },
    shelfMarket() {
      this.shelfMarketLoader = true;
      const getShelfMarket = new Promise((resolve) => {
        wsUtils.GetShelfMarket(
          {
            userId: this.user.EncrypteduserID,
          },
          resolve
        );
      });

      getShelfMarket.then((data) => {
        this.shelfMarketInfo = data;
        this.shelfMarketLoader = false;
      });
    },
    setQuery() {
      var data = Object.assign({}, this.$route.query);
      data["category"] = this.chosenShelfCategory;
      data["market"] = this.chosenShelfCountry;
      data["implicit"] = this.implicitValue;
      this.$router.push({ name: "shelfLibrary", query: data });
    },
    productDetails(id) {
      this.$router.push({
        path: "/shelf-details",
        query: {
          shelfID: id,
        },
      });
    },
    storeDemo(cipid) {
      window.open(
        `https://s3.us-east-1.amazonaws.com/simage.hotspex.com/mobileApp/shop3D/demo/labs.html?scanPath=&startDirection=1&storeConfig=https://s3.amazonaws.com/simage.hotspex.com/Projects/c${cipid}/autoJSON/shop/template.json`,
        "_blank"
      );
    },
    filterShelf() {
      (this.chosenShelfCategory = this.$route.query.category),
        (this.chosenShelfCountry = this.$route.query.market),
        this.shelfLibrary();
    },
    hsUserAccount() {
      if (this.user.EmailId.includes("hotspex.com")) {
          this.hsUser = true;
        } else {
          this.hsUser = false;
        }
    }
  },
  activated() {
   
    this.chosenShelfCategory = this.$route.query.category || "All";
    this.chosenShelfCountry = this.$route.query.market || "All";
    this.user = jsUtils.getUserInfo();
    this.hsUserAccount();
    this.client =
      jsUtils.getCurClient() || JSON.parse(jsUtils.readCookie("HS-STUDIO"));
    this.shelfLibrary();
    this.shelfCategory();
    this.shelfMarket();
    this.legsNumber = this.$route.query.legs;
    this.deepDiveNumber = this.$route.query.deepDive;
    this.sampleNumber = this.$route.query.sampleSize;
    this.implicitValue = this.$route.query.implicit === 'true';
    this.adaptPackPrice();
  },
};
</script>

<style lang="scss" scoped>
.shelf-library {
  min-height: 100%;
  &__loader {
    padding: 30px 0;
    min-height: 250px;
  }
  &__cost {
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    grid-gap: 20px 12px;
    max-width: 960px;
    width: 100%;
    margin-bottom: 15px;
  }
  &__cost-item {
    background: linear-gradient(220deg, #f7fff7 -26.91%, #ffffff 55.2%);
    position: relative;
    height: 115px;
    border-radius: 12px;
    padding: 11px 14px;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    border-bottom: 2px solid #4caf50;
    box-shadow: 0 0 17px 1px rgba(0, 0, 0, 0.05);

    &--pack {
      border-color: #2196f3;
      background: linear-gradient(220deg, #f4faff -26.91%, #ffffff 55.2%);
      .anticon-info-circle {
        color: #2196f3;
      }
    }
    &--shelf {
      border-color: #ffc107;
      background: linear-gradient(220deg, #fffdf7 -26.91%, #ffffff 55.2%);
      .anticon-info-circle {
        color: #ffc107;
      }
    }
    &--sample-size {
      border-color: #f44336;
      background: linear-gradient(220deg, #fff6f6 -26.91%, #ffffff 55.2%);
      .anticon-info-circle {
        color: #f44336;
      }
    }
    &--estimated {
      border-color: #3e7740;
      background: linear-gradient(220deg, #fff6f6 -26.91%, #ffffff 55.2%);
      .anticon-info-circle {
        color: #3e7740;
      }
    }

    .anticon-info-circle {
      position: absolute;
      top: 8px;
      right: 8px;
      color: var(--dark);
      cursor: pointer;
    }
    span {
      font-size: 27px;
      font-weight: 700;
    }
    h2 {
      font-size: 15px;
      font-weight: 500;
      color: #8995ad;
      line-height: 18px;
      margin-top: 3px;
      span {
        display: block;
        font-weight: 400;
        font-size: 12px;
      }
    }
    .circle-loader {
      position: absolute;
      top: 7px;
      right: 7px;
      margin: 0;
      width: 18px;
      height: 18px;
    }
  }
}

.filters-dropdown .ant-dropdown-menu {
  // width: 430px;
  width: 540px;
}
</style>
