<template>
  <div class="quota-list">
    <div class="quota-list__data-info" v-if="loading">
      <Loader text="Loading..."></Loader>
    </div>
    <div v-else>
      <div class="quota-list__table flex-1">
        <a-table
          tableLayout="auto"
          class="custom-table custom-table--remove-hover"
          :columns="columns"
          :row-key="(record, index) => `${record.surveyNumber}`"
          :data-source="quotaDetails.SurveyData"
          :loading="loading"
          :pagination="pagination"
          @change="handleTableChange"
        >
          <div
            slot="Tools"
            slot-scope="record"
            class="flex items-center justify-end gap-10"
          >
            <a-tooltip>
              <template slot="title"> Test </template>
              <button
                class="text-btn text-btn--dark text-left mr-10"
                type="link"
                @click="editQuota(record)"
              >
                <font-awesome-icon icon="eye" />
              </button>
            </a-tooltip>
          </div>
        </a-table>
      </div>
    </div>
  </div>
</template>

<script>
import * as wsUtils from "@/utils/wsUtils.js";
import Loader from "@/components/general/Loader.vue";

const columns = [
  {
    title: "Cell",
    dataIndex: "surveyCellName",
  },
  {
    title: "Quota",
    dataIndex: "quotaName",
  },
  {
    title: "",
    scopedSlots: { customRender: "Tools" },
  },
];

export default {
  name: "TestQuota",
  components: {
    Loader,
  },
  props: {
    surveyId: {
      type: String,
    },
  },
  data() {
    return {
      loading: false,
      quotaDetails: {
        SurveyData: [],
      },
      columns,
      pagination: {},
    };
  },
  computed: {},
  methods: {
    handleTableChange(pagination) {
      const pager = { ...this.pagination };
      pager.current = pagination.current;
      this.pagination = pager;
    },

    getDetails() {
      this.loading = true;
      const data = {
        wsName: "GetSurveyFulcrumData",
        data: {
          surveyId: this.surveyId,
        },
      };

      const getDetails = new Promise((resolve) => {
        wsUtils.ApiPost(data, resolve);
      });

      getDetails.then((data) => {
        if (!data.success) {
          this.$notification["info"]({
            message: "Info",
            description: "There is no quota available to proceed.",
            placement: "bottomLeft",
            duration: 4,
          });
          this.$emit("close");
        } else {
          this.quotaDetails = data.surveyFulcrumData;
          const pagination = { ...this.pagination };
          pagination.total = this.quotaDetails.SurveyData.length;
          pagination.hideOnSinglePage = true;
          pagination.pageSize = 7;
          this.pagination = pagination;
        }
        this.loading = false;
      });
    },
    editQuota(record) {
      window.open(record.fulcrumUrlWithData, "_blank", "noreferrer");
    },
  },
  mounted() {
    this.getDetails();
  },
};
</script>

<style lang="scss" scoped>
.quota-list {
  position: relative;
  &__data-info {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
</style>
