<template>
  <div class="shelf-preview">
    <div
      class="shelf-preview__close"
      :disabled="loading"
      @click="$emit('close')"
    ></div>
    <div class="shelf-preview__overlay"></div>
    <div class="shelf-preview__inner position-r">
      <h2>Preview Shelf Image</h2>
      <div
        class="shelf-preview__image-wrapper position-r"
        id="shelf-modal-img"
        :class="{ 'image-loading': loadingImage || loading }"
      >
        <panZoom id="modal-panzoom-details">
          <div class="zoomable">
            <img :src="shelfImage" @load="onLoad" />
          </div>
        </panZoom>
      </div>
      <div
        class="flex items-center shelf-preview__tools-zoom"
        :disabled="loading"
      >
        <div class="flex items-center shelf-preview__tools-zoom-controls">
          <div
            class="
              flex
              items-center
              justify-center
              shelf-preview__tools-zoom-cta
            "
            @click="zoom(-1)"
            :disabled="imageZoomPercent === 25"
          >
            -
          </div>
          <span>{{ imageZoomPercent }}%</span>
          <div
            class="
              flex
              items-center
              justify-center
              shelf-preview__tools-zoom-cta shelf-preview__tools-zoom-cta--plus
            "
            @click="zoom(1)"
            :disabled="imageZoomPercent === 600"
          >
            +
          </div>
        </div>
      </div>
      <div class="shelf-preview__controls flex justify-end">
        <a-button
          type="dark"
          :disabled="loading || loadingDownloadImage"
          class="b-radius-40"
          @click="$emit('recreate-shelf-image')"
        >
          <a-icon type="reload" /> Recreate Shelf Image
        </a-button>
        <a-button
          type="dark"
          :disabled="loading || loadingDownloadImage"
          class="b-radius-40"
          :loading="loadingDownloadImage"
          @click="$emit('download')"
        >
          <font-awesome-icon
            icon="download"
            class="mr-5"
            v-if="!loadingDownloadImage"
          />
          Download Image
        </a-button>
        <a-button
          type="primary"
          :disabled="loading"
          class="b-radius-40"
          @click="$emit('close')"
        >
          Done
        </a-button>
      </div>
    </div>
  </div>
</template>

<script>
import Panzoom from "panzoom";

export default {
  name: "ShelfPreviewModal",
  components: {},
  props: {
    shelfImage: {
      type: String,
      default() {
        return "";
      },
    },
    loading: {
      type: Boolean,
      default() {
        return "";
      },
    },
    loadingDownloadImage: {
      type: Boolean,
    },
  },
  data() {
    return {
      currentZoomLevel: 1,
      imageZoomPercent: 50,
      loadingImage: true,
      imageCurrentWidth: 1,
    };
  },
  computed: {},
  watch: {},
  methods: {
    zoom(level) {
      const container = document.querySelector("#shelf-modal-img");
      const rect = container.getBoundingClientRect();
      const cx = rect.x + rect.width / 2;
      const cy = rect.y + rect.height / 2;

      const zoomLevels = [0.5, 1, 1.5, 2, 3, 4, 5, 6, 7];
      const currentIndex = zoomLevels.indexOf(this.currentZoomLevel);
      let newIndex;
      if (level === -1) {
        newIndex = currentIndex - 1;
      } else {
        newIndex = currentIndex + 1;
      }
      if (newIndex >= 0 && newIndex < zoomLevels.length) {
        const newZoomLevel = zoomLevels[newIndex];
        this.panzoomDetails.zoomAbs(cx, cy, newZoomLevel);
        this.currentZoomLevel = newZoomLevel;
        this.setImageZoomPercent(newZoomLevel);
      }
    },
    setImageZoomPercent(zoomStep) {
      switch (zoomStep) {
        case 0.5:
          this.imageZoomPercent = 25;
          break;
        case 1:
          this.imageZoomPercent = 50;
          break;
        case 1.5:
          this.imageZoomPercent = 75;
          break;
        case 2:
          this.imageZoomPercent = 100;
          break;
        case 3:
          this.imageZoomPercent = 200;
          break;
        case 4:
          this.imageZoomPercent = 300;
          break;
        case 5:
          this.imageZoomPercent = 400;
          break;
        case 6:
          this.imageZoomPercent = 500;
          break;
        case 7:
          this.imageZoomPercent = 600;
          break;
        default:
          break;
      }
    },
    onLoad() {
      this.loadingImage = false;
    },
  },
  mounted() {
    this.panzoomDetails = Panzoom(
      document.getElementById("modal-panzoom-details"),
      {
        bounds: true,
        boundsPadding: 0.1,
        maxZoom: 7,
        minZoom: 0.1,
        transformOrigin: { x: 0.5, y: 0.5 },
        disableZoom: true,
        beforeWheel: function (e) {
          var shouldIgnore = !e.altKey;
          return shouldIgnore;
        },
        onTouch: function () {
          return false;
        },
      }
    );
    this.activeShelf = this.currentShelf;
  },
  created() {
    this.image = this.$refs.image;
  },
  activated() {},
};
</script>

<style lang="scss" scoped>
.shelf-preview {
  position: fixed;
  left: 96px;
  top: 0;
  right: 0;
  height: 100%;
  z-index: 999;
  display: flex;
  align-items: center;
  justify-content: center;
  &__close {
    position: absolute;
    top: 25px;
    right: -12px;
    transform: translate(-50%, -50%);
    width: 40px;
    height: 40px;
    cursor: pointer;
    z-index: 1;

    &:before,
    &:after {
      content: "";
      position: absolute;
      top: 50%;
      left: 0;
      width: 100%;
      height: 2px;
      background-color: #555555;
      transition: background-color 0.3s ease;
    }

    &:before {
      transform: rotate(45deg);
    }

    &:after {
      transform: rotate(-45deg);
    }
    &:hover {
      &:before,
      &:after {
        background-color: #fff;
      }
    }
    &[disabled] {
      pointer-events: none;
      opacity: 0.5;
    }
  }
  &__arrow {
    position: absolute;
    left: -45px;
    top: 50%;
    transform: translateY(-50%) rotate(-135deg);
    width: 34px;
    height: 34px;
    cursor: pointer;
    z-index: 1;
    border-color: #555555;
    border-width: 2px 2px 0 0;
    border-style: solid;
    transition: color 0.3s ease;
    &--right {
      left: auto;
      right: -45px;
      transform: translateY(-50%) rotate(45deg);
    }
    &:hover {
      border-color: #000;
    }
    &--disabled {
      pointer-events: none;
      opacity: 0.25;
    }
  }
  &__overlay {
    position: absolute;
    inset: 0;
    background: rgba(0, 0, 0, 0.94);
  }
  &__inner {
    max-width: 85%;
    background: #1a1b1d;
    border-radius: 6px;
    z-index: 1;
    width: 100%;
    position: relative;
    box-shadow: 0px 4px 12px 3px rgba(0, 0, 0, 0.14);
    padding: 8px 15px;
    height: 90%;
    display: flex;
    flex-direction: column;
    margin: 0 80px;
    h2 {
      font-size: 18px;
      padding: 8px 0;
      color: #fff;
    }
  }
  &__image-wrapper {
    flex: 1;
    overflow: hidden;
    border-radius: 6px;
    background: #2e2e2e;
    margin-bottom: 15px;
    img {
      width: 100%;
      height: 100%;
    }
    ::v-deep {
      .zoomable {
        transform: none !important;
      }
    }
  }
  &__info {
    .shelf-library-item__info {
      padding-left: 3px;
      li {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
  }
  &__controls {
    border-top: 1px solid #4c4d4e;
    margin: 14px -15px 0;
    padding: 10px 15px 2px 10px;
    gap: 10px;
    .ant-btn {
      height: 36px;
    }
  }
  &__tools {
    position: absolute;
    left: 0;
    width: 100%;
    background: #f5f5f5;
    bottom: 0;
    width: 100%;
    padding: 8px 10px;
    box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.55);
  }
  &__tools-zoom {
    position: absolute;
    left: 50%;
    bottom: 100px;
    transform: translateX(-50%);
    &[disabled] {
      pointer-events: none;
      opacity: 0.5;
    }
    > span {
      padding-right: 5px;
      font-size: 14px;
      color: #c6c6c6;
    }
  }
  &__tools-zoom-controls {
    background: rgba(230, 230, 230, 0.9);
    padding: 9px 6px;
    border-radius: 4px;
    min-width: 110px;
    display: flex;
    justify-content: space-between;
    span {
      padding: 0 8px;
      font-size: 14px;
      font-weight: 500;
    }
  }
  &__tools-zoom-cta {
    width: 24px;
    height: 24px;
    border-radius: 50%;
    border: 2px solid #2e2e2e;
    font-size: 26px;
    background: #2e2e2e;
    color: #fff;
    cursor: pointer;
    transition: 0.3s ease all;
    &[disabled] {
      opacity: 0.7;
      cursor: not-allowed;
    }
    &:hover {
      background: #262626;
    }
    &--plus {
      font-size: 18px;
    }
  }
  &__count {
    position: absolute;
    right: 15px;
    bottom: 15px;
    background: #e6e6e6;
    padding: 2px 6px;
    font-weight: 500;
    border-radius: 4px;
    background: rgba(230, 230, 230, 0.9);
    font-size: 14px;
  }
}
</style>
