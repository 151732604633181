<template>
  <div class="quotas authoring-wrapper">
    <div class="flex">
      <ProgressBar :stepIndex="5" :surveyID="surveyID" stepsType="pack-sbd" />
      <section class="wrapper">
        <a-breadcrumb class="breadcrum breadcrum--authoring">
          <template v-if="user !== null && user.SkinIdentifier !== 'pg'">
            <a-breadcrumb-item
              ><a href="/">{{ client.ClientName }}</a></a-breadcrumb-item
            >
            <a-breadcrumb-item
              ><a href="/products">Products</a></a-breadcrumb-item
            >
            <a-breadcrumb-item>Pack SBD Test</a-breadcrumb-item>
          </template>
        </a-breadcrumb>

        <div class="wrapper__inner">
          <div class="page-title page-title--transparent-svg-color">
            <icon-base
              :width="32"
              :height="33"
              :viewBox1="485"
              :viewBox2="485"
              iconName="users"
              iconColor="#4318FF"
              iconStroke="transparent"
            >
              <icon-users-quotas />
            </icon-base>
            <div class="page-title__text">
              <h1>Quotas</h1>
              <span>Configure survey quotas</span>
            </div>
          </div>
          <CustomQuotasFields
            v-show="activeStep === 1"
            ref="customQuotasFields"
            @nextStep="showStep2"
            :country="
              editableSurvey && editableSurvey.SurveyConfig
                ? editableSurvey.SurveyConfig.Country
                : ''
            "
            :activeStep="activeStep"
            @nextBtnLabel="nextBtnLabel = $event"
            @update:quizs="quizs = $event"
          ></CustomQuotasFields>

          <div class="box-wrapper position-r" v-show="activeStep === 2">
            <div v-if="!loading">
              <div
                class="mb-25 position-r"
                v-for="category in quotaData"
                :key="category.quotaCategory"
              >
                <h2>{{ category.quotaCategory }} Quotas</h2>

                <div class="flex input-group-wrapper">
                  <div
                    class="input-group input-group--small"
                    v-for="quota in category.quotaList"
                    :key="quota.id"
                  >
                    <label>{{ quota.name }}</label>
                    <div class="position-r">
                      <a-input-number
                        step="1"
                        :max="100"
                        :min="0"
                        placeholder=""
                        class="w-full"
                        :precision="0"
                        @focus="(event) => event.target.select()"
                        v-model="quotas[category.quotaCategory][quota.id]"
                        :class="{
                          error:
                            !isCategoryValid(category.quotaCategory) &&
                            isSubmitted,
                        }"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <Loader
              v-else
              style="margin: 200px auto"
              text="Loading..."
            ></Loader>
            <p
              v-if="!isAllValidComputed && isSubmitted && !loading"
              class="error-message"
            >
              Your quotas do not add up to 100%. Please adjust the highlighted
              fields.
            </p>
          </div>

          <StepNaviBar
            :stepIndex="4"
            :conciergeAssistance="true"
            @nextHandler="nextStep"
            :prevDisable="false"
            @prevHandler="prevStep"
            @loading-survey-data="loadingSurveyData = $event"
            @editable-survey-updated="handleEditableSurveyUpdate"
            prevBtnTooltip="Back to Brand Strategy"
            nextBtnTooltip="Go to Launch"
            :nextBtnLabel="nextBtnLabel"
            :loadingNextBtn="loadingNextBtn"
            stepsType="pack"
          />
        </div>
      </section>
    </div>

    <CompletionProgress :checkList="quizs"></CompletionProgress>
  </div>
</template>

<script>
import * as wsUtils from "@/utils/wsUtils.js";
import ProgressBar from "@/components/videoAds/ProgressBar.vue";
import StepNaviBar from "@/components/videoAds/StepNaviBar1.vue";
import surveyPriceMixin from "@/mixins/surveyPriceCalculation.js";
import * as jsUtils from "@/utils/jsUtils.js";
import IconBase from "@/components/general/IconBase.vue";
import IconUsersQuotas from "@/components/icons/IconUsersQuotas.vue";
import CompletionProgress from "@/components/general/CompletionProgress.vue";
import CustomQuotasFields from "@/components/packTestSurvey/CustomQuotasFields.vue";
import Loader from "@/components/general/Loader.vue";

export default {
  name: "Quotas",
  components: {
    ProgressBar,
    StepNaviBar,
    IconBase,
    IconUsersQuotas,
    CompletionProgress,
    Loader,
    CustomQuotasFields,
  },
  mixins: [surveyPriceMixin],
  data() {
    return {
      loading: true,
      surveyID: null,
      user: null,
      client: null,
      quizs: [],
      isSubmitted: false,
      quotaData: [],
      quotas: {},
      loadingSurveyData: true,
      editableSurvey: undefined,
      activeStep: 1,
      nextButtonClicked: false,
      loadingNextBtn: false,
      nextBtnLabel: "Next",
    };
  },
  computed: {
    isAllValidComputed() {
      return (
        this.isAgeValid() &&
        this.isGenderValid() &&
        this.isRegionValid() &&
        this.isIncomeValid() &&
        this.isUserValid() &&
        this.isEthnicityValid()
      );
    },
  },
  methods: {
    isCategoryValid(categoryName) {
      if (this.activeStep !== 2) return false;
      const categoryQuotas = this.quotas[categoryName];
      if (!categoryQuotas) return false;

      const total = Object.values(categoryQuotas).reduce(
        (sum, value) => sum + value,
        0
      );

      // For Ethnicity, we check if all values are non-empty
      if (categoryName === "Ethnicity") {
        const isCompleted = Object.values(categoryQuotas).every(
          (value) => value !== "" && value !== null
        );

        // Update the completed status for the Ethnicity quiz
        const ethnicityQuiz = this.quizs.find(
          (quiz) => quiz.id === categoryName
        );
        if (ethnicityQuiz) {
          ethnicityQuiz.completed = isCompleted;
        }

        return isCompleted;
      }
      // For other categories, total must equal 100

      const quiz = this.quizs.find((quiz) => quiz.id === categoryName);
      if (quiz) {
        quiz.completed = total === 100;
      } else {
        console.warn(`Quiz not found for categoryName: ${categoryName}`);
      }
      return total === 100;
    },
    isEthnicityValid() {
      return this.isCategoryValid("Ethnicity");
    },
    isAgeValid() {
      return this.isCategoryValid("Age");
    },
    isGenderValid() {
      return this.isCategoryValid("Gender");
    },
    isRegionValid() {
      return this.isCategoryValid("Region");
    },
    isIncomeValid() {
      return this.isCategoryValid("Income");
    },
    isUserValid() {
      return this.isCategoryValid("User");
    },
    // checkCategoryValid() {
    //   this.isCategoryValid("Ethnicity");
    //   this.isCategoryValid("Age");
    //   this.isCategoryValid("Gender");
    //   this.isCategoryValid("Region");
    //   this.isCategoryValid("Income");
    //   this.isCategoryValid("User");
    // },
    nextStep() {
      if (this.activeStep === 1) {
        this.$refs.customQuotasFields.validateAllInputs();
      } else {
        this.isSubmitted = true;
        if (!this.isAllValidComputed) return;
        this.saveQuotas();
      }
    },
    prevStep() {
      if (this.activeStep === 2) {
        this.activeStep = 1;
        window.scrollTo({
          top: 0,
          behavior: "smooth",
        });
      } else {
        this.$router.push({
          name: "pack-sbd-category-inputs",
          params: { id: this.surveyID },
        });
      }
    },
    updateQuizCompletion(quizName, isValid) {
      const quiz = this.quizs.find((quiz) => quiz.quizName === quizName);
      if (quiz) {
        quiz.completed = isValid;
      }
    },
    initializeQuotas() {
      this.quizs = [];
      this.quotaData.forEach((category) => {
        this.quizs.push({
          id: category.quotaCategory,
          quizName: `Add ${category.quotaCategory} Quotas`,
          completed: false,
        });
        this.$set(this.quotas, category.quotaCategory, {});
        category.quotaList.forEach((quota) => {
          this.$set(this.quotas[category.quotaCategory], quota.id, 0);
        });
      });
      this.setEditableQuotas();
    },
    setEditableQuotas() {
      if (
        this.editableSurvey &&
        this.editableSurvey.quotaList &&
        this.editableSurvey.quotaList.length > 0
      ) {
        this.editableSurvey.quotaList.forEach((quota) => {
          const { quotaConfigId, precentage } = quota;

          for (const category in this.quotas) {
            if (
              Object.prototype.hasOwnProperty.call(
                this.quotas[category],
                quotaConfigId
              )
            ) {
              this.quotas[category][quotaConfigId] = precentage;
              break;
            }
          }
        });
      }
      this.loading = false;
    },

    getQuotaConfig() {
      const model = {
        wsName: "GetQuotaConfig",
        params: {
          surveyTypeId: 107,
          countryId: this.editableSurvey.SurveyConfig.CountryId,
        },
      };

      const getQuota = new Promise((resolve) => {
        wsUtils.ApiGet(model, resolve);
      });

      getQuota.then((data) => {
        this.quotaData = data;
        this.initializeQuotas();
      });
    },
    getCustomQuotaConfig() {
      const model = {
        wsName: "GetSurveyQuotaConfig",
        params: {
          surveyId: this.$route.params.id,
        },
      };

      const getQuota = new Promise((resolve) => {
        wsUtils.ApiGet(model, resolve);
      });

      getQuota.then((data) => {
        this.quotaData = data;
        this.initializeQuotas();
      });
    },
    saveQuotas() {
      const quotaPercentages = [];

      Object.keys(this.quotas).forEach((category) => {
        Object.keys(this.quotas[category]).forEach((quotaConfigId) => {
          const percentage = this.quotas[category][quotaConfigId];

          quotaPercentages.push({
            quotaConfigId: parseInt(quotaConfigId, 10),
            percentage: percentage,
          });
        });
      });

      const data = {
        wsName: "SaveQuotaPercentage",
        data: {
          surveyId: this.surveyID,
          userId: this.user.EncrypteduserID,
          quotaPercentages: quotaPercentages,
        },
      };

      const saveQuotas = new Promise((resolve) => {
        wsUtils.ApiPost(data, resolve);
      });

      saveQuotas.then((data) => {
        if (data.Success) {
          this.$router.push({
            name: "pack-sbd-test-launch",
            params: { id: this.surveyID },
          });
        } else {
          this.$notification["error"]({
            message: "Error!",
            description:
              "Save quotas failed. Please try again or contact our support.",
            placement: "bottomLeft",
            duration: 5,
          });
        }
      });
    },
    handleEditableSurveyUpdate(surveyData) {
      if (
        surveyData &&
        surveyData.SurveyConfig &&
        surveyData.SurveyConfig.BrandName &&
        surveyData.SurveyConfig.SurveyName
      ) {
        this.editableSurvey = surveyData;
        //this.getCustomQuotaConfig();
      } else {
        this.editableSurvey = undefined;
        this.$notification["error"]({
          message: "Error!",
          description:
            "Load survey failed. Please try again or contact our support.",
          placement: "bottomLeft",
          duration: 5,
        });
      }
      this.loadingNextBtn = false;
    },
    showStep2(quotasType) {
      this.loading = true;
      if (quotasType === "custom") {
        this.getCustomQuotaConfig();
      } else {
        this.getQuotaConfig();
      }
      this.activeStep = 2;
      //this.checkCategoryValid();
    },
  },
  activated() {
    this.activeStep = 1;
    this.loadingNextBtn = true;
    this.surveyID = this.$route.params.id;
    this.user = jsUtils.getUserInfo();
    this.client = jsUtils.getCurClient();
    this.isSubmitted = false;
  },
};
</script>

<style lang="scss" scoped>
.quotas {
  h2 {
    font-size: 18px;
    margin-bottom: 5px;
  }
}
.input-group-wrapper {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  gap: 20px;
  margin-bottom: 20px;
}
.error-message {
  color: var(--red);
  font-size: 14px;
  position: absolute;
  bottom: 2px;
  left: 24px;
  font-weight: 500;
}
</style>
