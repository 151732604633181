<template>
  <div class="authoring-cta">
    <div class="btn-help" v-if="conciergeAssistance" id="bottomNavSupportBtn">
      <a-button
        size="large"
        @click="openHelpModal"
        class="flex items-center justify-center"
      >
      </a-button>
    </div>

    <div
      class="position-r flex gap-10 authoring-cta__wrapper"
      v-if="!generalNaviBar"
    >
      <a-button
        id="bottomNavPrevBtn"
        size="large"
        @click="prevStep"
        :disabled="prevDisable"
        v-if="!hidePrevButton"
        ><icon-base
          class="ml-5 back"
          :width="20"
          :height="20"
          :viewBox1="20"
          :viewBox2="20"
          iconName="longArrowRight"
          iconStroke="#D04395"
          ><icon-long-arrow-right
        /></icon-base>
        Back
      </a-button>

      <a-button
        size="large"
        id="bottomNavCancelBtn"
        @click="$emit('cancel:draft')"
        v-if="cancelButton"
      >
        Cancel
      </a-button>

      <a-button
        type="primary"
        size="large"
        @click="nextStep"
        :disabled="nextDisable"
        :class="{ 'pointer-events-none': loading }"
        id="bottomNavNextBtn"
        class="flex items-center"
        :loading="loadingNextBtn"
      >
        <template v-if="!loading">
          <span v-show="!isEnd">{{ nextBtnLabel || "Next" }}</span>
          <span v-show="isEnd"
            ><template v-if="accountPaymentType === 'Invoiced'">Launch</template
            ><template v-else>
              <span>Pay and Launch</span>
            </template>
          </span>
          <icon-base
            class="ml-5"
            :width="20"
            :height="20"
            :viewBox1="20"
            :viewBox2="20"
            iconName="longArrowRight"
            iconStroke="#fff"
            style="margin-top: -3px"
            ><icon-long-arrow-right
          /></icon-base>
        </template>
        <i class="circle-loader" v-else></i>
      </a-button>

      <div
        class="authoring-cta__loading-message"
        v-if="loading && user.SkinIdentifier === 'pg'"
      >
        Please wait. We're checking the feasibility of your study.
      </div>
    </div>

    <a-modal
      title="Concierge Assistance"
      :visible="conciergeModal"
      @cancel="conciergeModal = false"
      :afterClose="closeConciergeModal"
      :footer="null"
      :width="350"
      class="concierge-modal"
    >
      <p class="mb-20">
        If you'd like, you can hand off the configuration of your study to an
        expert from the Hotspex team! Please provide a couple of details about
        your project and an explanation of the research objectives.
      </p>
      <a-row
        :gutter="16"
        v-if="!conciergeModalLoading && !conciergeRequestSent"
      >
        <a-col :span="24" class="input-group mb-15">
          <div class="flex items-center">
            <label class="lineLable">Number of SKUs</label>
            <a-tooltip placement="top" overlayClassName="ant-tooltip--step-nav">
              <template slot="title">
                <span
                  >This is the number of packs that will be changing on each
                  shelf.</span
                >
              </template>
              <a-icon type="info-circle" />
            </a-tooltip>
          </div>
          <a-input-number
            class="fullWidth"
            size="large"
            type="number"
            :min="1"
            :max="100000"
            v-model="conciergeSKUs"
            placeholder="Insert number of SKUs"
            :class="{
              'error-field':
                conciergeSKUs === null && formValidations.length > 0,
            }"
        /></a-col>
        <a-col :span="24" class="input-group mb-15">
          <div class="flex items-center">
            <label class="lineLable">Number of tests</label>
            <a-tooltip placement="top" overlayClassName="ant-tooltip--step-nav">
              <template slot="title">
                <span
                  >This is the number of legs in your study
                  <strong>including Control</strong>.</span
                >
              </template>
              <a-icon type="info-circle" />
            </a-tooltip>
          </div>
          <a-select
            class="fullWidth"
            option-filter-prop="children"
            placeholder="Select number of tests"
            :key="Math.random()"
            @change="conciergeVersions = $event"
            :default-value="conciergeVersions || undefined"
            :class="{
              'error-field':
                conciergeVersions === null && formValidations.length > 0,
            }"
          >
            <a-select-option
              v-for="(item, index) in [1, 2, 3, 4, '5+']"
              :key="index"
              :value="item"
            >
              {{ item }}
            </a-select-option>
          </a-select></a-col
        >
      </a-row>

      <div
        v-else
        class="concierge-modal__info flex items-center justify-center"
      >
        <Loader
          v-if="conciergeModalLoading"
          text="Please wait, your data will be saved soon..."
          contentPosition="vertical"
        ></Loader>
        <div v-else class="concierge-modal__info-success">
          <p>Your request is successfully sent.</p>
          <a href="#">Go to Dashboard</a>
        </div>
      </div>
      <div class="concierge-modal__chat flex items-center flex-col">
        <p><span>OR</span></p>
        <div>
          <a
            target="_blank"
            class="btn-teams-chat flex items-center justify-center"
            href="https://teams.microsoft.com/l/message/19:WwjKEmUXIAbR-bwR18w8rTSSCYHV8TOrFH440_LnnlM1@thread.tacv2/1689246846667?tenantId=1ca0aff4-f44d-4645-8f6d-b49dd858ae14&groupId=a8180bd2-6ee9-410e-8c41-68ddfc46f7b1&parentMessageId=1689246846667&teamName=P%26G S365 Support&channelName=&createdTime=1689246846667"
            ><i></i>Live Chat</a
          >
        </div>
      </div>
      <a-row :gutter="16" class="mt-10">
        <a-col :span="12">
          <ul
            class="validation-list validation-list--basic"
            v-if="formValidations.length > 0"
          >
            <li v-for="(item, index) in formValidations" :key="index">
              {{ item }}
            </li>
          </ul>
        </a-col>
        <a-col :span="12" class="flex justify-end">
          <a-button
            class="flex items-center mr-10"
            @click="conciergeModal = false"
          >
            <span>Cancel</span>
          </a-button>
          <a-button
            type="primary"
            @click="saveConcierge"
            :disabled="conciergeModalLoading || conciergeRequestSent"
          >
            Submit
          </a-button></a-col
        >
      </a-row>
    </a-modal>
  </div>
</template>

<script>
import * as wsUtils from "@/utils/wsUtils.js";
import * as jsUtils from "@/utils/jsUtils.js";
import Loader from "@/components/general/Loader.vue";
import IconBase from "@/components/general/IconBase.vue";
import IconLongArrowRight from "@/components/icons/IconLongArrowRight.vue";
import surveyPriceMixin from "@/mixins/surveyPriceCalculation.js";
import { mutations } from "@/utils/store.js";

export default {
  name: "StepNaviBar",
  components: {
    Loader,
    IconBase,
    IconLongArrowRight,
  },
  mixins: [surveyPriceMixin],
  props: {
    stepIndex: Number,
    nextDisable: Boolean,
    prevDisable: Boolean,
    isEnd: Boolean,
    accountPaymentType: String,
    loading: Boolean,
    cancelButton: Boolean,
    stepsType: {
      type: String,
      default() {
        return "video";
      },
    },
    prevBtnTooltip: {
      type: String,
      default() {
        return "";
      },
    },
    nextBtnTooltip: {
      type: String,
      default() {
        return "";
      },
    },
    nextBtnLabel: {
      type: String,
    },
    conciergeAssistance: {
      type: Boolean,
      default() {
        return false;
      },
    },
    generalNaviBar: {
      type: Boolean,
      default() {
        return false;
      },
    },
    loadingNextBtn: {
      type: Boolean,
    },
  },
  data() {
    return {
      conciergeModal: false,
      conciergeSKUs: null,
      conciergeVersions: null,
      conciergeModalLoading: false,
      conciergeRequestSent: false,
      formValidations: [],
      user: {
        dashboardURL: "",
      },
    };
  },
  methods: {
    setActiveChatBtn() {
      this.conciergeModal = false;
      document.getElementById("silc-btn").classList.add("active");
      this.removeActiveChatBtn();
    },
    removeActiveChatBtn() {
      document
        .getElementById("silc-btn")
        .addEventListener("click", function () {
          document.getElementById("silc-btn").classList.remove("active");
        });
    },
    nextStep() {
      this.$emit("nextHandler");
    },
    prevStep() {
      this.$emit("prevHandler");
    },
    getSurveyData() {
      let wsType;

      this.$emit("loading-survey-data", true);

      if (this.stepsType === "pack") {
        wsType = "GetPackTestSurveyDetails";
      } else if (this.stepsType === "Reticle Alignment Test") {
        wsType = "GetSurveyStimReticle";
      } else if (this.stepsType === "map-maker-test") {
        wsType = "GetMapSurveyDetails";
      } else {
        wsType = "GetCombineSurveyDetails";
      }

      const surveyPromise = new Promise((resolve) => {
        wsUtils[wsType](
          {
            SurveyID: this.$route.params.id,
            SurveyTypeID: jsUtils.getSurveyTypeId(),
          },
          resolve
        );
      });
      surveyPromise.then((data) => {
        jsUtils.setEditableSurvey(data);
        this.$emit("editable-survey-updated", data);
        if (this.stepsType === "pack") {
          this.getPackTestTotalPrice();
        }
        this.$emit("loading-survey-data", false);
      });
    },
    closeConciergeModal() {
      this.formValidations = [];
      this.conciergeModalLoading = false;
      this.conciergeRequestSent = false;
      this.conciergeSKUs = null;
      this.conciergeVersions = null;
    },
    sendConciergeRequest() {
      const conciergeRequest = new Promise((resolve) => {
        wsUtils.ConciergeRequest(
          {
            sucks: this.conciergeSKUs,
            versions: this.conciergeVersions,
            preferedContact: "Email",
            UserId: this.user.EncrypteduserID,
            SurveyID: this.$route.params.id,
          },
          resolve
        );
      });

      conciergeRequest.then((data) => {
        if (data.success) {
          setTimeout(() => {
            this.conciergeModalLoading = false;
            this.conciergeRequestSent = true;
          }, 1000);
        }
      });
    },
    saveConcierge() {
      this.formValidations = [];
      if (this.conciergeSKUs === null || this.conciergeVersions === null) {
        this.formValidations.push("All fields are required.");
      } else {
        this.conciergeModalLoading = true;
        this.sendConciergeRequest();
      }
    },
    openHelpModal() {
      mutations.openHelpModal(true, "support");
    },
  },
  computed: {
    hidePrevButton() {
      return this.stepIndex === 1 ||
        (this.user.dashboardURL !== "" &&
          this.stepIndex === 2 &&
          this.stepsType === "pack")
        ? true
        : false;
    },
  },
  mounted() {
    this.user = jsUtils.getUserInfo();
  },
  activated() {
    setTimeout(() => {
      if (!this.generalNaviBar) {
        this.getSurveyData();
      }
    }, 0);
  },
};
</script>

<style lang="scss" scoped>
.authoring-cta {
  display: flex;
  justify-content: flex-end;
  padding: 25px 0;

  &__wrapper {
    gap: 24px;
  }

  .ant-btn {
    min-width: 95px;
    svg {
      &.back {
        transform: translateY(3px) rotate(180deg);
      }
    }
    .circle-loader {
      margin: 0 auto !important;
    }
  }

  &__loading-message {
    position: absolute;
    bottom: calc(100% + 20px);
    background: #fff;
    padding: 10px 15px;
    background: #fff;
    color: var(--darkGrey);
    border-radius: 2px;
    box-shadow: 0 4px 12px #00000026;
    font-size: 14px;
    text-align: left;
    line-height: 19px;
    width: 270px;
    right: 0;
    &:after {
      position: absolute;
      right: 29px;
      transform: translate(-50%, 3px);
      bottom: -3px;
      content: "";
      width: 0;
      height: 0;
      border-left: 10px solid transparent;
      border-right: 10px solid transparent;
      border-top: 10px solid #fff;
      transition: all 0.2s ease;
    }
  }
}

.btn-help {
  position: fixed;
  right: 40px;
  bottom: 40px;
  z-index: 999;

  .ant-btn {
    min-width: auto;
    width: 70px;
    height: 70px;
    border-radius: 50%;
    flex-direction: column;
    box-shadow: none !important;
    background: url("/images/headset.svg") center no-repeat;
    z-index: 1;
    border: 0;
    background-size: contain;
    @media screen and (max-width: 800px) {
      width: 56px;
      height: 56px;
    }

    &:hover {
      box-shadow: 0px 0px 9px 3px rgba(0, 0, 0, 0.16);
    }
  }
}

.concierge-modal {
  &__info {
    height: 237px;
    border: 1px solid #e9e9e9;
    border-radius: 4px;
    font-size: 12px;
  }
  &__info-success {
    text-align: center;
    p {
      font-size: 18px;
      background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3E%3Cpath d='M10 0C4.5 0 0 4.5 0 10s4.5 10 10 10 10-4.5 10-10S15.5 0 10 0ZM8 15l-5-5 1.4-1.4L8 12.2l7.6-7.6L17 6l-9 9Z' fill='%2329dbb7' fill-rule='evenodd'/%3E%3C/svg%3E")
        no-repeat center 0 #fff;
      background-size: 26px;
      padding-top: 30px;
    }
    a {
      font-size: 14px;
      text-decoration: underline;
      color: var(--hsblue);
    }
  }
  &__chat {
    margin-bottom: 21px;
    padding-bottom: 34px;
    border-bottom: 1px solid #f0f0f0;
    p {
      position: relative;
      text-align: center;
      width: 100%;
      &::before {
        position: absolute;
        left: 0;
        width: 100%;
        top: 10px;
        content: "";
        border-bottom: 1px solid #f0f0f0;
      }
      span {
        background: #fff;
        z-index: 1;
        position: relative;
        padding: 0 7px;
      }
    }
  }
}
</style>

<style lang="scss">
.ant-tooltip {
  &--step-nav {
    .ant-tooltip-inner {
      font-size: 12px;
      min-height: auto;
      color: #000;
      background: #fff;
      border: 1px solid #ffffff;
      box-shadow: 0 0px 10px 5px rgba(0, 0, 0, 0.1);
      padding: 8px 8px 6px;
    }
    .ant-tooltip-arrow {
      &:before {
        background: #fff;
      }
    }
    .ant-tooltip-content {
      border: 1px solid #e7e7e7;
      border-radius: 4px;
    }
  }
}
</style>
