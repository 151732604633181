<template>
  <div class="authoring-wrapper">
    <div class="authoring">
      <div class="flex">
        <ProgressBar
          :stepIndex="progressStepIndex"
          :surveyID="surveyID"
          :stepsType="progressStepsType"
        />
        <section class="wrapper">
          <a-breadcrumb class="breadcrum breadcrum--authoring">
            <template v-if="user !== null && user.SkinIdentifier !== 'pg'">
              <a-breadcrumb-item
                ><a href="/">{{ client.ClientName }}</a></a-breadcrumb-item
              >
              <a-breadcrumb-item
                ><a href="/products">Products</a></a-breadcrumb-item
              >
              <a-breadcrumb-item>{{ breadcrumbTitle }}</a-breadcrumb-item>
            </template>
          </a-breadcrumb>

          <div class="wrapper__inner">
            <div class="stepAuthor" ref="stepAuthorSection">
              <div class="page-title page-title--transparent-svg-color">
                <icon-base
                  :width="32"
                  :height="33"
                  :viewBox1="32"
                  :viewBox2="33"
                  iconName="rocket"
                  iconStroke="#4318FF"
                  ><icon-rocket
                /></icon-base>
                <div class="page-title__text">
                  <h1>Launch</h1>
                  <span>Summary & Confirmation</span>
                </div>
              </div>
              <div class="box-wrapper">
                <a-row
                  :gutter="16"
                  class="flex mb-25"
                  v-if="surveyTypeID !== 108"
                >
                  <a-col :span="12" style="width: 52%">
                    <ActiveShelf
                      :activeShelf="
                        projectSummary !== null
                          ? projectSummary.activeShelf
                          : null
                      "
                      :showEdit="false"
                      :showRetailer="true"
                    ></ActiveShelf>
                  </a-col>
                  <a-col :span="12" style="width: 48%">
                    <SelectedProducts
                      :selectedProducts="
                        projectSummary !== null
                          ? projectSummary.chosenProducts
                          : null
                      "
                      :showEdit="false"
                    ></SelectedProducts
                  ></a-col>
                </a-row>
                <a-row :gutter="16" class="flex mb-25" v-else>
                  <a-col :span="24">
                    <SelectedBrands
                      :selectedBrands="
                        projectSummary ? projectSummary.mapMakerBrandData : null
                      "
                    ></SelectedBrands>
                  </a-col>
                </a-row>
                <div>
                  <a-descriptions bordered :column="2">
                    <a-descriptions-item
                      :label="langObj['s6-summary'].summaryProjectName"
                    >
                      {{ projectSummary ? projectSummary.projectName : "/" }}
                    </a-descriptions-item>

                    <a-descriptions-item
                      :label="langObj['s6-summary'].summaryCountry"
                    >
                      <span
                        v-html="
                          projectSummary !== null ? projectSummary.country : '/'
                        "
                      ></span>
                    </a-descriptions-item>

                    <a-descriptions-item
                      :label="langObj['s6-summary'].summaryCategory"
                    >
                      {{
                        projectSummary !== null && "category" in projectSummary
                          ? projectSummary.category
                          : "/"
                      }}
                    </a-descriptions-item>

                    <a-descriptions-item
                      v-if="surveyTypeID !== 108"
                      :label="langObj['s6-summary'].summaryBrand"
                    >
                      <span
                        v-html="
                          projectSummary !== null ? projectSummary.brand : '/'
                        "
                      ></span>
                    </a-descriptions-item>
                    <a-descriptions-item
                      v-if="surveyTypeID !== 108"
                      :label="langObj['s6-summary'].summaryLegsName"
                    >
                      <ul v-if="projectSummary !== null" class="mb-0 pl-0">
                        <li
                          v-for="(item, index) in projectSummary.legsName"
                          :key="index"
                        >
                          {{ item }}
                          <br v-if="index < projectSummary.legsName.length" />
                        </li>
                      </ul>
                    </a-descriptions-item>
                    <a-descriptions-item
                      v-if="surveyTypeID !== 108"
                      :label="
                        projectSummary &&
                        projectSummary.studyType &&
                        projectSummary.studyType === 'SBD'
                          ? langObj['s6-summary'].summaryFindabilityProducts
                          : langObj['s6-summary'].summaryDeepDiveProducts
                      "
                    >
                      <ul v-if="projectSummary !== null" class="mb-0 pl-0">
                        <li
                          v-for="(
                            item, index
                          ) in projectSummary.deepDiveProductsName"
                          :key="index"
                        >
                          {{ item
                          }}<span
                            v-if="
                              index + 1 <
                              projectSummary.deepDiveProductsName.length
                            "
                            >,</span
                          >
                          <br
                            v-if="
                              index < projectSummary.deepDiveProductsName.length
                            "
                          />
                        </li>
                      </ul>
                    </a-descriptions-item>
                    <a-descriptions-item
                      v-if="surveyTypeID !== 108"
                      :label="
                        langObj['s6-summary'].summaryCommunicationObjective
                      "
                    >
                      {{
                        projectSummary !== null
                          ? projectSummary.chosenCommunicationObjective1
                              .CommunicationObjectiveName
                          : "/"
                      }}<span
                        v-if="
                          projectSummary !== null &&
                          projectSummary.chosenCommunicationObjective2 !== -1
                        "
                        >,</span
                      >
                      {{
                        projectSummary !== null
                          ? projectSummary.chosenCommunicationObjective2
                              .CommunicationObjectiveName
                          : "/"
                      }}
                    </a-descriptions-item>

                    <a-descriptions-item :label="sampleLabel">
                      <div class="flex items-center sample-size">
                        <div
                          @click="openSampleSize = !openSampleSize"
                          :class="{ disabled: !changeSampleSize }"
                          @focusout="openSampleSize = false"
                        >
                          <a-select
                            class="sample-size__select"
                            :class="{
                              'sample-size__select--disabled':
                                !changeSampleSize,
                            }"
                            @change="selectSampleSize"
                            :default-value="sampleSize"
                            :disabled="!changeSampleSize"
                            :open="openSampleSize"
                            :dropdownMatchSelectWidth="false"
                            :key="Math.random()"
                          >
                            <a-select-option
                              v-for="(item, index) in [
                                75, 100, 150, 200, 250, 300,
                              ]"
                              :key="index"
                              :value="item"
                            >
                              {{ item }}
                            </a-select-option>
                          </a-select>
                        </div>
                        <div>
                          <a-button
                            type="primary"
                            class="btn-change-sample"
                            v-if="!changeSampleSize"
                            @click="
                              (openSampleSize = true), (changeSampleSize = true)
                            "
                          >
                            Change</a-button
                          >
                          <a-button
                            v-else
                            type="primary"
                            class="btn-change-sample"
                            @click="
                              (openSampleSize = false),
                                (changeSampleSize = false)
                            "
                            >Close</a-button
                          >
                        </div>
                      </div>
                    </a-descriptions-item>

                    <a-descriptions-item
                      v-if="surveyTypeID !== 108"
                      :label="langObj['s6-summary'].summaryCostCenter"
                    >
                      {{
                        editableSurvey !== null
                          ? editableSurvey.SurveyConfig.CostCenter !== ""
                            ? editableSurvey.SurveyConfig.CostCenter
                            : "/"
                          : "/"
                      }}
                    </a-descriptions-item>
                    <a-descriptions-item
                      :label="
                        langObj['s6-summary'].summaryEstimateCompletionDate
                      "
                    >
                      {{ estimateCompletionDate }}
                    </a-descriptions-item>

                    <a-descriptions-item
                      :label="langObj['s6-summary'].summaryReportingOption"
                      v-if="
                        user &&
                        user.SkinIdentifier !== 'pg' &&
                        surveyTypeID !== 108
                      "
                    >
                      <div class="input-group input-group--small">
                        <a-select
                          class="report-option-dropdown"
                          option-filter-prop="children"
                          show-search
                          placeholder="Please select"
                          :key="Math.random()"
                          @change="setReportOption"
                          :default-value="reportType"
                        >
                          <a-select-option
                            v-for="item in reportingOptions"
                            :key="item"
                            :value="item"
                          >
                            {{ item }}
                          </a-select-option>
                        </a-select>
                      </div>
                    </a-descriptions-item>
                    <a-descriptions-item
                      :label="langObj['s6-summary'].summaryImplicitComponent"
                      v-if="
                        ((projectSummary && !projectSummary.studyType) ||
                          (projectSummary &&
                            projectSummary.studyType &&
                            projectSummary.studyType !== 'SBD')) &&
                        surveyTypeID !== 108
                      "
                    >
                      <a-checkbox
                        v-model="implicitComponent"
                        @change="setImplicitComponent"
                      >
                        <span v-if="!implicitComponent">Enable</span>
                        <span v-else>Disable</span>
                      </a-checkbox>
                    </a-descriptions-item>
                    <a-descriptions-item
                      :label="langObj['s6-summary'].summaryCustomQuestions"
                      v-if="
                        user &&
                        user.SkinIdentifier !== 'pg' &&
                        surveyType !== 'packtestextend'
                      "
                    >
                      <template v-if="projectSummary !== null">
                        <ol class="mb-0 pl-15">
                          <li
                            v-for="(
                              item, index
                            ) in projectSummary.customQuestions"
                            :key="index"
                          >
                            {{ item }}
                          </li>
                        </ol>
                        <span v-if="projectSummary.customQuestions.length === 0"
                          >/</span
                        >
                      </template>
                    </a-descriptions-item>
                  </a-descriptions>

                  <div class="launch-step__total">
                    <div class="agreement">
                      <a-checkbox v-model="privacyCheck">
                        <span
                          v-html="langObj['s6-summary'].summaryPrivacy"
                        ></span>
                      </a-checkbox>
                      <span>
                        <a
                          href="#"
                          v-html="langObj['s6-summary'].summaryPrivacyLink"
                          class="terms"
                        ></a>
                      </span>
                      <div v-if="privacyCheckValidation">
                        <p
                          class="validation-text font-size-14 mt-10"
                          v-html="langObj['s6-summary'].summaryPrivacyMessage"
                        ></p>
                      </div>
                    </div>
                    <div>
                      <a-descriptions bordered :column="1" class="total-table">
                        <a-descriptions-item label="Estimated price:">
                          <span class="flex items-center justify-end">
                            ${{ formatPrice(getPackTestPrice) }}
                            <a-tooltip
                              placement="top"
                              overlayClassName="ant-tooltip--small ant-tooltip--step-nav"
                              v-if="surveyTypeID !== 108"
                            >
                              <template slot="title">
                                <span
                                  v-html="
                                    langObj['s6-summary'].summarySampleSizeInfo
                                  "
                                ></span>
                              </template>

                              <a-icon type="question-circle" />
                            </a-tooltip>
                          </span>
                        </a-descriptions-item>
                        <a-descriptions-item
                          label="Estimated OOP:"
                          v-if="surveyTypeID !== 108"
                        >
                          <span class="flex items-center justify-end">
                            ${{ formatPrice(costDetails.estimatedCost) }}
                            <a-tooltip
                              placement="top"
                              overlayClassName="ant-tooltip--small ant-tooltip--step-nav"
                            >
                              <template slot="title">
                                <span class="d-block"
                                  >Cost for sample: ${{
                                    formatPrice(costDetails.costForSample)
                                  }}</span
                                >
                                <span class="d-block"
                                  >Maintenance cost: ${{
                                    formatPrice(costDetails.maintenanceCost)
                                  }}</span
                                >
                                <span class="d-block"
                                  >Data processing: ${{
                                    formatPrice(costDetails.dataProcessing)
                                  }}</span
                                >
                              </template>

                              <a-icon type="question-circle" />
                            </a-tooltip>
                          </span>
                        </a-descriptions-item>
                        <a-descriptions-item label="Enter PO:">
                          <div class="input-group input-group--small">
                            <a-input v-model="po"></a-input>
                          </div>
                        </a-descriptions-item>
                      </a-descriptions>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <StepNaviBar
              :stepIndex="stepIndex"
              :nextDisable="false"
              @nextHandler="nextStep"
              :prevDisable="getQuotaConfigLoading && isPackSbdTestPresent"
              :isEnd="true"
              @prevHandler="prevStep"
              :accountPaymentType="accountPaymentType"
              :loading="
                loading ||
                getPriceLoader ||
                finalPriceCalculationLoader ||
                getCheckingFeasibilityLoader
              "
              prevBtnTooltip="Back to Brand Strategy"
              :conciergeAssistance="
                $route.name === 'pack-test-launch' ? true : false
              "
              :stepsType="
                $route.name === 'pack-test-launch' ? 'pack' : 'map-maker-test'
              "
            />
          </div>
        </section>
      </div>
      <form :action="apiUrl" method="POST" ref="payLaunchForm">
        <input type="hidden" name="SurveyId" id="SurveyId" :value="surveyID" />
        <input type="hidden" name="Price" id="Price" :value="finalPrice" />
      </form>
    </div>
  </div>
</template>

<script>
import * as wsUtils from "@/utils/wsUtils.js";
import * as jsUtils from "@/utils/jsUtils.js";
import ProgressBar from "@/components/videoAds/ProgressBar.vue";
import StepNaviBar from "@/components/videoAds/StepNaviBar1.vue";
import ActiveShelf from "@/components/packTestSurvey/ActiveShelf.vue";
import SelectedProducts from "@/components/packTestSurvey/SelectedProducts.vue";
import surveyPriceMixin from "@/mixins/surveyPriceCalculation.js";
import { store } from "@/utils/store.js";
import priceFormating from "@/mixins/priceFormating.js";
import IconBase from "@/components/general/IconBase.vue";
import IconRocket from "@/components/icons/IconRocket.vue";
import SelectedBrands from "@/components/mapMakerTest/SelectedBrands.vue";

export default {
  name: "Summary",
  components: {
    ProgressBar,
    StepNaviBar,
    ActiveShelf,
    SelectedProducts,
    IconBase,
    IconRocket,
    SelectedBrands,
  },
  mixins: [surveyPriceMixin, priceFormating],
  data() {
    return {
      privacyCheck: true,
      privacyCheckValidation: false,
      stepIndex: 6,
      surveyID: 0,
      client: {},
      projectSummary: null,
      langObj: {},
      price: null,
      accountPaymentType: null,
      equalPrices: false,
      loading: false,
      apiUrl: null,
      surveyTypeID: null,
      finalPrice: null,
      sampleSize: 100,
      openSampleSize: false,
      changeSampleSize: false,
      user: null,
      editableSurvey: null,
      finalPriceCalculationLoader: false,
      estimateCompletionDate: null,
      surveyType: null,
      reportingOptions: ["Automated", "Custom"],
      reportType: "Automated",
      implicitComponent: true,
      isPackSbdTestPresent: false,
      breadcrumbTitle: "Pack Test",
      quotaHasData: false,
      getQuotaConfigLoading: true,
      po: null,
      costDetails: {
        estimatedCost: null,
        costForSample: null,
        maintenanceCost: null,
        dataProcessing: null,
      },
    };
  },

  watch: {
    privacyCheck(newVal) {
      if (newVal) {
        this.privacyCheckValidation = false;
      }
    },
  },
  computed: {
    getPackTestPrice() {
      return store.surveyPackTestPrice;
    },
    getPriceLoader() {
      return store.priceLoader.length > 0;
    },
    getCheckingFeasibilityLoader() {
      return store.checkingFeasibilityLoader.length > 0;
    },
    sampleLabel() {
      const { projectSummary, langObj } = this;
      if (this.surveyTypeID === 108) {
        return langObj["s6-summary"].summarySampleBrandMap;
      } else if (projectSummary && projectSummary.studyType === "SBD") {
        return langObj["s6-summary"].summarySamplePerFindabilityExercise;
      } else {
        return langObj["s6-summary"].summarySampleSizePerLeg;
      }
    },
    progressStepsType() {
      const routeName = this.$route.name;
      if (routeName === "map-maker-test-launch") {
        return "map-maker";
      } else if (routeName === "pack-sbd-test-launch") {
        return "pack-sbd";
      } else {
        return "pack";
      }
    },
    progressStepIndex() {
      const routeName = this.$route.name;
      if (routeName === "map-maker-test-launch") {
        return 4;
      } else {
        return 6;
      }
    },
  },
  methods: {
    prevStep() {
      let prevStep;

      if (this.isPackSbdTestPresent === true) {
        prevStep = !this.quotaHasData
          ? "pack-sbd-category-inputs"
          : "pack-quotas";
      } else if (this.surveyTypeID === 108) {
        prevStep = "stimuli-map-maker";
      } else {
        prevStep =
          this.user.SkinIdentifier !== "pg" &&
          this.surveyType !== "packtestextend"
            ? "custom-questions"
            : "communication-objective";
      }

      this.$router.push({
        name: prevStep,
        params: { id: this.surveyID },
      });
    },
    launchSurvey() {
      this.loading = true;
      const launchPromise = new Promise((resolve) => {
        wsUtils.PackTestLaunchSurvey(
          {
            SurveyId: this.surveyID,
            userId: this.user.EncrypteduserID,
            poValue: this.po,
          },
          resolve
        );
      });

      launchPromise.then((data) => {
        this.loading = false;
        if (data.success) {
          let currentSummaryData = jsUtils.getSurveySummaryData();
          jsUtils.setSurveySummaryData({
            ...currentSummaryData,
            surveyPackTestPrice: store.surveyPackTestPrice,
          });
          this.$router.push({
            name: "launch",
            params: { id: this.$route.params.id },
          });
        } else {
          this.$notification["error"]({
            message: `Oops!`,
            description: data.message,
            placement: "bottomLeft",
            duration: 10,
          });
        }
      });
    },
    launchMapMaker() {
      this.loading = true;
      const launchPromise = new Promise((resolve) => {
        wsUtils.LaunchSurvey(
          { SurveyId: this.surveyID, poValue: this.po },
          resolve
        );
      });

      launchPromise.then((data) => {
        this.loading = false;
        if (data.success) {
          this.$router.push({
            name: "home",
          });
          this.$message.success(data.message, 6);
        } else {
          this.$notification["error"]({
            message: `Oops!`,
            description: data.message,
            placement: "bottomLeft",
            duration: 10,
          });
        }
      });
    },
    nextStep() {
      let runSurveyMode = jsUtils.getRunSurveyMode();
      let feasibilitySuccess =
        this.user.SkinIdentifier !== "pg"
          ? "true"
          : jsUtils.getFeasibilitySuccess();
      let runMessage = "";
      let demoUser = this.user.UserType === "Guest" ? true : false;

      if (demoUser) {
        runMessage =
          "DEMO accounts cannot launch surveys. Please upgrade for full access.";
      } else if (!feasibilitySuccess) {
        runMessage = "Feasibility not confirmed. Please, contact us.";
      } else if (!runSurveyMode && !feasibilitySuccess) {
        runMessage =
          "You can't 'Pay & Launch' study because you haven't filled in all the information for the Pack test and Feasibility not confirmed.";
      } else {
        runMessage =
          "You can't 'Pay & Launch' study because you haven't filled in all the information for the Pack test.";
      }

      if (!runSurveyMode || !feasibilitySuccess || demoUser) {
        this.$notification["warning"]({
          message: `Oops`,
          description: runMessage,
          placement: "bottomLeft",
          duration: 10,
        });
      } else {
        if (
          this.getPackTestPrice === this.finalPrice ||
          this.surveyTypeID === 108
        ) {
          if (this.privacyCheck) {
            this.privacyCheckValidation = false;
            jsUtils.setSurveysApiTrigger(true);
            if (
              this.accountPaymentType === "Invoiced" &&
              this.surveyTypeID === 108
            ) {
              this.launchMapMaker();
            } else if (this.accountPaymentType === "Invoiced") {
              this.launchSurvey();
            } else {
              this.loading = true;
              this.$refs.payLaunchForm.submit();
            }
            jsUtils.deleteCookie("survey-type");
          } else {
            this.privacyCheckValidation = true;
            const stepAuthorSection = this.$refs.stepAuthorSection;
            stepAuthorSection.scrollTop = stepAuthorSection.scrollHeight;
          }
        } else {
          this.$router.push({
            name: "global-error-page",
            params: {
              id: "1321-12423",
              surveyId: this.surveyID,
              description: `Pack test - Survey ID: ${this.surveyID}, Cliend ID: ${this.client.ClientId}, Client Name: ${this.client.ClientName}, Total Price - Front end calculation: ${this.getPackTestPrice}, Final Price - Back end calculation: ${this.finalPrice}`,
            },
          });
        }
      }
    },
    selectSampleSize(size) {
      this.openSampleSize = false;
      this.sampleSize = size;

      const saveSampleSize = new Promise((resolve) => {
        wsUtils.SaveSampleSize(
          {
            surveyId: this.$route.params.id,
            sampleSize: size,
            userId: this.user.EncrypteduserID,
          },
          resolve
        );
      });
      saveSampleSize.then((data) => {
        if (data.Success === true) {
          this.$notification["success"]({
            message: "Sample Updated!",
            description:
              "Your sample and project costs have been updated successfully.",
            placement: "bottomLeft",
            duration: 6,
          });
          this.changeSampleSize = false;
          if (this.surveyTypeID === 108) {
            this.getMapMakerTotalPrice();
          } else {
            this.priceCalculation();
            this.adaptPackPrice();
          }
          this.getSurveyData();
        } else if (data.Status === 0) {
          this.$notification["error"]({
            message: "Error!",
            description:
              "Something was wrong, please try again or contact our support.",
            placement: "bottomLeft",
            duration: 6,
          });
        } else {
          this.$notification["error"]({
            message: "Error!",
            description:
              "The feasibility of this study is at risk or not feasible. Please choose less sample per deep dive.",
            placement: "bottomLeft",
            duration: 6,
          });
        }
      });
    },
    priceCalculation() {
      let currentPackTestPriceData = jsUtils.getPackTestPrice();
      jsUtils.setPackTestPrice({
        ...currentPackTestPriceData,
        explicitSamplePerDeepDive: this.sampleSize,
      });
      this.getPackTestTotalPrice();
      this.getPackTestFinalPrice();
    },
    getPackTestFinalPrice() {
      this.finalPriceCalculationLoader = true;
      const GetPackTestFinalPrice = new Promise((resolve) => {
        wsUtils.GetPackTestFinalPrice(this.$route.params.id, resolve);
      });
      GetPackTestFinalPrice.then((data) => {
        this.finalPrice = data.total;
        if (this.getPackTestPrice === data.total) {
          this.equalPrices = true;
        } else {
          this.equalPrices = false;
        }
        this.finalPriceCalculationLoader = false;
      });
    },
    adaptPackPrice() {
      const getAdaptPackPrice = new Promise((resolve) => {
        wsUtils.GetAdaptPackPrice(
          {
            sample: this.sampleSize,
            legs: this.projectSummary.legsName.length,
            deepDive: this.projectSummary.deepDiveProductsName.length,
            surveyTypeId: this.surveyTypeID,
          },
          resolve
        );
      });

      getAdaptPackPrice.then((data) => {
        this.costDetails = data;
      });
    },
    getSurveyData() {
      let wsType = "GetPackTestSurveyDetails";
      const surveyPromise = new Promise((resolve) => {
        wsUtils[wsType](
          {
            SurveyID: this.$route.params.id,
            SurveyTypeID: jsUtils.getSurveyTypeId(),
          },
          resolve
        );
      });
      surveyPromise.then((data) => {
        jsUtils.setEditableSurvey(data);
      });
    },
    setDefaultSampleSize() {
      const saveSampleSize = new Promise((resolve) => {
        wsUtils.SaveSampleSize(
          {
            surveyId: this.$route.params.id,
            sampleSize: 100,
            userId: this.user.EncrypteduserID,
          },
          resolve
        );
      });
      saveSampleSize.then(() => {});
    },
    getDateAfter10Days() {
      const today = new Date();
      const futureDate = new Date(today.getTime() + 10 * 24 * 60 * 60 * 1000);
      this.estimateCompletionDate = futureDate.toLocaleDateString();
    },
    setReportOption(e) {
      let currentSummaryData = jsUtils.getSurveySummaryData();
      let currentPackTestPriceData = jsUtils.getPackTestPrice();

      this.reportType = e;
      let data = {
        SurveyID: this.surveyID,
        ReportOptions: e,
      };
      const updateReportOptions = new Promise((resolve) => {
        wsUtils.UpdateReportOptions(data, resolve);
      });

      updateReportOptions.then((data) => {
        if (data.Success) {
          jsUtils.setSurveySummaryData({
            ...currentSummaryData,
            ReportOption: e,
          });

          jsUtils.setPackTestPrice({
            ...currentPackTestPriceData,
            ReportOption: e,
          });
          this.getPackTestTotalPrice();
          this.getPackTestFinalPrice();
        }
      });
    },
    setImplicitComponent() {
      let currentSummaryData = jsUtils.getSurveySummaryData();
      let currentPackTestPriceData = jsUtils.getPackTestPrice();

      let data = {
        SurveyID: this.surveyID,
        implicitComponent: this.implicitComponent,
      };
      const setImplicitComponent = new Promise((resolve) => {
        wsUtils.SetImplicitComponent(data, resolve);
      });

      setImplicitComponent.then((data) => {
        if (data.Success) {
          jsUtils.setSurveySummaryData({
            ...currentSummaryData,
            implicitComponent: this.implicitComponent,
          });

          jsUtils.setPackTestPrice({
            ...currentPackTestPriceData,
            implicitComponent: this.implicitComponent,
          });
          this.getPackTestTotalPrice();
          this.getPackTestFinalPrice();
        }
      });
    },
    checkUrlForPackSbdTest() {
      const currentPath = this.$route.path;
      this.isPackSbdTestPresent = currentPath.includes("pack-sbd-test");
      if (this.isPackSbdTestPresent) {
        this.breadcrumbTitle = "Pack SBD Test";
      } else if (this.surveyTypeID === 108) {
        this.breadcrumbTitle = "Map Maker Test";
      } else {
        this.breadcrumbTitle = "Pack Test";
      }
    },
    getQuotaConfig() {
      if (!this.isPackSbdTestPresent) {
        return;
      }
      const model = {
        wsName: "GetQuotaConfig",
        params: {
          surveyTypeId: 107,
          countryId: this.editableSurvey.SurveyConfig.CountryId,
        },
      };

      const getQuota = new Promise((resolve) => {
        wsUtils.ApiGet(model, resolve);
      });

      getQuota.then((data) => {
        if (data.length > 0) {
          this.quotaHasData = true;
        }
        this.getQuotaConfigLoading = false;
      });
    },
  },
  mounted() {
    this.client = jsUtils.getCurClient();
  },
  activated() {
    this.po = null;
    this.surveyTypeID = jsUtils.getSurveyTypeId();
    this.checkUrlForPackSbdTest();
    this.surveyType = jsUtils.readCookie("survey-type");
    this.surveyID = this.$route.params.id;
    this.user = jsUtils.getUserInfo();
    this.editableSurvey = jsUtils.getEditableSurvey();
    if (
      this.editableSurvey &&
      this.editableSurvey.SurveyConfig.SurveyStatus !== "Draft"
    ) {
      this.$router.push("/");
    }
    if (this.editableSurvey && this.editableSurvey.audience) {
      this.sampleSize = this.editableSurvey.audience.sampleSize;
    } else {
      this.sampleSize = 100;
    }
    if (
      window.location.hostname === "app.hotspexlabs.com" ||
      window.location.hostname === "pg.hotspexlabs.com" ||
      window.location.hostname === "d3n7xxuq0fwznj.cloudfront.net" ||
      window.location.hostname === "d30wz03irsnsp8.cloudfront.net" ||
      window.location.hostname === "d11qiq41szmpd8.cloudfront.net"
    ) {
      this.apiUrl =
        "https://admin.hotspexlabs.com/Cart/create-checkout-session";
    } else if (window.location.hostname === "app-uat.hotspexstudio.com") {
      this.apiUrl =
        "https://admin-uat.hotspexstudio.com/Cart/create-checkout-session";
    } else {
      this.apiUrl =
        "https://staging-admin.hotspexstudio.com/Cart/create-checkout-session";
    }

    this.projectSummary = jsUtils.getSurveySummaryData();
    this.accountPaymentType = jsUtils.getAccountPaymentType();
    this.reportType = this.projectSummary.ReportOption;
    this.implicitComponent = this.projectSummary.implicitComponent;

    if (this.isPackSbdTestPresent) {
      this.implicitComponent = false;
      this.setImplicitComponent();
    }

    if (this.surveyTypeID === 108) {
      this.getMapMakerTotalPrice();
    } else {
      this.getPackTestFinalPrice();
      this.adaptPackPrice();
    }
    this.getDateAfter10Days();
    this.getQuotaConfig();
  },
  created() {
    this.langObj = jsUtils.getLangObj(
      "videoAuthorLang",
      "/tooltip/videoAuthor.json"
    );
  },
  beforeRouteEnter(to, from, next) {
    if (from.name === "launch") {
      window.open("https://vpt.pg.com", "_self", "noreferrer");
    }
    next();
  },
};
</script>

<style lang="scss" scoped>
.info {
  h2 {
    font-size: 16px;
    font-weight: 600;
  }
}
.stepAuthor {
  display: block;
  width: 100%;
  margin: auto;
  max-width: 1010px;
  ::v-deep .ant-descriptions-bordered {
    &.total-table {
      .ant-descriptions-item-label {
        padding: 18px 12px;
        width: 154px;
      }
      .ant-descriptions-item-content {
        min-width: 114px;
      }
    }
    .ant-descriptions-item-label {
      padding: 9px 12px;
      width: 270px;
      max-width: 270px;
      font-size: 12px;
      font-weight: 600;
      background: #f0f0f0;
      border-color: #b9b9b9;
    }
  }
  ::v-deep .ant-descriptions-bordered .ant-descriptions-view {
    border-color: #b9b9b9;
  }
  ::v-deep .ant-descriptions-bordered .ant-descriptions-item-content {
    min-width: 140px;
    color: #1c1c1c;
  }
  ::v-deep .ant-descriptions-item-content {
    width: 265px;
    padding: 9px 12px;
    font-size: 12px;
    border-color: #b9b9b9;
  }
  ::v-deep .ant-descriptions-row {
    border-color: #b9b9b9;
  }
}

.agreement {
  padding-right: 20px;
  padding: 16px 12px 13px;
  border-radius: 5px;
  background: #f4f7fe;
  flex: 1;
  h4 {
    font-size: 16px;
    font-weight: bold;
  }

  a {
    color: var(--red);
    text-decoration: underline;
    margin-left: -5px;
    font-weight: 500;
    letter-spacing: -0.32px;
  }

  label {
    font-size: 16px;
    font-weight: 500;
    color: rgba(28, 28, 28, 0.9);
    letter-spacing: -0.32px;
  }

  .terms {
    padding-left: 43px;
    display: inline-flex;
  }

  ::v-deep .ant-checkbox-inner {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    border-color: var(--red);
    background: var(--red);
    &:after {
      width: 9px;
      height: 18px;
      top: 45%;
    }
  }

  ::v-deep .ant-checkbox-checked::after {
    border-radius: 50%;
    border-color: var(--red);
  }
}

.btn-change-sample {
  height: 22px;
  padding: 0 7px;
  font-size: 13px;
  margin-left: 8px;
}

.sample-size {
  &__select {
    width: 48px;
    ::v-deep .ant-select-arrow {
      pointer-events: none;
      font-size: 9px;
      right: 4px;
    }
    ::v-deep .ant-select-selection__rendered {
      margin-right: 0;
      margin-left: 6px;
    }
    &--disabled {
      ::v-deep .ant-select-selection {
        border-color: transparent;
        background: transparent;
        color: rgba(0, 0, 0, 0.65);
      }
      ::v-deep .ant-select-arrow {
        opacity: 0;
        transition: opacity 0.3s ease;
      }
    }
  }
  .ant-btn {
    width: 60px;
    margin-left: 5px;
  }
}

.disabled {
  pointer-events: none;
}
</style>

<style lang="scss">
.ant-descriptions-item-label {
  font-weight: 500;
  background-color: #f6fcff;
  color: var(--hsgrey) !important;
}

.launch-step {
  padding-bottom: 80px !important;
  &__video-info {
    margin: 40px 0 0;
    border-width: 1px 1px 0;
    border-style: solid;
    border-color: #e8e8e8;
    border-radius: 4px;
    .ant-table-thead > tr > th,
    .ant-table-tbody > tr > td {
      border-right: 1px solid #e8e8e8;
      padding: 16px 24px;
      &:last-child {
        border-right: 0;
      }
    }
    .ant-table-tbody {
      tr {
        &:hover {
          td {
            background: #fff !important;
          }
        }
      }
    }
  }
  &__total {
    display: flex;
    justify-content: space-between;
    margin-top: 24px;
    gap: 24px;

    .ant-descriptions {
      max-width: 376px !important;
      width: 100%;
      font-weight: 600;
      .anticon {
        margin-left: 8px;
        display: block;
        cursor: pointer;
        svg {
          width: 15px;
          height: 15px;
        }
      }
    }
  }
}

.report-option-dropdown {
  width: 152px;
}
</style>
